import React from 'react'

const AdminLayoutCardBaslik = (props) => {
  return (
    <>
        {props.title && (
            <div className='font-black text-lg my-2'>
                {props.title}
            </div>
        )}
    </>

  )
}

export default AdminLayoutCardBaslik