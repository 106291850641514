import React from 'react'
import { useStateContext } from '../../contexts/ContextProvider';

const Kriter02 = (props) => {
    const {screenSize} = useStateContext();
  return (
    <div className={`${screenSize > 1200 ? "w-1/6" : (screenSize < 700 ? "w-full" : "w-1/3")} flex flex-col pr-2`}>
        <span className={`mb-2 font-bold dark:text-gray-100`}>{props.title}</span>
        {props.children}
    </div>
  )
}

export default Kriter02