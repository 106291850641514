import React from 'react';
import AdminLayoutCardBaslik from '../../layouts/AdminLayoutCardBaslik';

const Card12 = (props) => {
  
  return (
    <div className={`bg-white w-full rounded-2xl mt-2 px-4 py-2 border-4 border-[#f2f7ff] shadow-inner`}>
        <AdminLayoutCardBaslik title={props.title} />
        {props.children}
    </div>
  )
}

export default Card12