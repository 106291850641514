import React from 'react'
import { useStateContext } from '../../contexts/ContextProvider';

const Kriter03 = (props) => {
    const {screenSize} = useStateContext();
  return (
    <div className={`${screenSize > 1200 ? "w-1/4" : (screenSize < 700 ? "w-full" : "w-1/2")} flex flex-col pr-2`}>
        <span className={`mb-2 font-bold `}>{props.title}</span>
        {props.children}
    </div>
  )
}

export default Kriter03