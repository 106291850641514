import React, { Fragment } from 'react';
import Yukleniyor from '../Yukleniyor';


const WrapRow = (props) => {
  return (
    <Fragment>
        <div className='w-full flex flex-wrap justify-items-start content-start'>
          {props.children}
        </div>
        {props.isLoading && (<Yukleniyor />)}
    </Fragment>
  )

}

export default WrapRow