import React from 'react'
import { ToastContainer } from 'react-toastify'
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import WrapRow from '../../components/designs/WrapRow';
import Card12 from '../../components/designs/Card12';

const Anasayfa = () => {
  return (
    <>
      <ToastContainer />
      <AdminLayoutPageHeader title="Anasayfa" />
      <WrapRow>
        <Card12>
        <div>
          aasdfasdf
        </div>
        </Card12>

      </WrapRow>
    </>
  )
}

export default Anasayfa