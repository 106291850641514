import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { FaAngleDown } from "react-icons/fa";
import {BiRightArrowAlt} from 'react-icons/bi';
import { NavLink } from "react-router-dom";
import '../assets/css/sidebar.css';

const menuAnimation = {
    hidden: {
      opacity: 0,
      height: 0,
      padding: 0,
      transition: { duration: 0.1, when: "afterChildren" },
    },
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.1,
        when: "beforeChildren",
      },
    },
  };
  const menuItemAnimation = {
    hidden: (i) => ({
      padding: 0,
      x: "-100%",
      transition: {
        duration: 0.4,
      },
    }),
    show: (i) => ({
      x: 0,
      transition: {
        duration: 0.3,
      },
    }),
  };

const SidebarItem = ({ route, showAnimation, isOpen, setIsOpen }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
      setIsOpen(true);
    };
  
    useEffect(() => {
      if (!isOpen) {
        setIsMenuOpen(false);
      }
    }, [isOpen]);

    return (
        <>
          <div className="sidebar-sidemenu" onClick={toggleMenu}>
            <div className="sidebar-sidemenu-item">
              <div className="sidebar-ikon">{route.ikon}</div>
              
                {isOpen && (
                  <AnimatePresence>
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="sidebar-linktext"
                    >
                      {route.adi}
                    </motion.div>
                  </AnimatePresence>
                )}
              
            </div>
            {isOpen && (
              <motion.div
                animate={
                  isMenuOpen
                    ? {
                        rotate: -90,
                      }
                    : { rotate: 0 }
                }
              >
                <FaAngleDown />
              </motion.div>
            )}
          </div>{" "}
          
            {isMenuOpen && (
              <AnimatePresence>
                <motion.div
                  variants={menuAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="sidebar-sidecontainer"
                >
                  {route.submenu.map((subRoute) => (
                    <motion.div variants={menuItemAnimation} key={subRoute.id} custom={subRoute.id}>
                      <NavLink to={subRoute.url} className={({ isActive }) => isActive ? "sidebar-active" : "sidebar-link"}>
                        <div className="sidebar-ikon"><BiRightArrowAlt size={24}/></div>
                        <motion.div className="sidebar-linktext">{subRoute.adi}</motion.div>
                      </NavLink>
                    </motion.div>
                  ))}
                </motion.div>
              </AnimatePresence>
            )}{" "}
          
        </>
      )
}

export default SidebarItem;