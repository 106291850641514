import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import { AnimatePresence,motion } from 'framer-motion';
import SidebarItem from './SidebarItem';
import {links} from '../datas/links';
import '../assets/css/sidebar.css';
import { useKullaniciAuth } from '../contexts/AdminAuthProvider';


const Sidebar = () => {
    const [isOpen,setIsOpen] = useState(true);
    const {kullanici} = useKullaniciAuth();
    const showAnimation = {
      hidden: {
        width: 0,
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      },
      show: {
        opacity: 1,
        width: "auto",
        transition: {
          duration: 0.3,
        },
      },
    };
    return (
      <section className="sidebar-alan">
        {links.map((route) => {
          if (route.submenuvar) {
            return (
              <SidebarItem key={route.id} setIsOpen={setIsOpen} route={route} showAnimation={showAnimation} isOpen={isOpen} />
            );
          }

          return (
            <NavLink to={route.url} key={route.id} className={({ isActive }) => isActive ? "sidebar-active" : "sidebar-link"}>
              <div className="sidebar-ikon">{route.ikon}</div>
              
                {isOpen && (
                  <AnimatePresence>
                    <motion.div variants={showAnimation} initial="hidden" animate="show" exit="hidden" className="sidebar-linktext" >
                      {route.adi}
                    </motion.div>
                  </AnimatePresence>
                )}
              
            </NavLink>
          );
          
        })}
      </section>
    )
  }
  
  export default Sidebar
