import { MdMessage, MdSettings} from "react-icons/md";
import { FaBusinessTime, FaFootballBall, FaTrophy, FaUser} from "react-icons/fa";



export const links = [
    {
        id:1,
        adi : "Anasayfa",        
        url:'/yonetici/anasayfa',
        ikon : <MdMessage size={24}/>,
        submenuvar : false,
        submenu :[]
    },
    {
        id:2,
        adi : "Kullanıcı Ayarlar",        
        url:'/yonetici/kullaniciayarlar',
        ikon : <MdSettings size={24}/>,
        submenuvar : false,
        submenu :[]
    },
    {
        id:10,
        adi : "Veri Yönetimi",
        url:'#',
        ikon : <FaBusinessTime size={24}/>,
        submenuvar : true,
        submenu :[
            {id:11,adi:"Ülkeler", url:"/yonetici/ulkeler" },
            {id:12,adi:"Şehirler", url:"/yonetici/sehirler" },
            {id:13,adi:"Stadyumlar", url:"/yonetici/stadyumlar" },
            {id:14,adi:"Takımlar", url:"/yonetici/takimlar" },
            {id:15,adi:"Kişiler", url:"/yonetici/kisiler" },           
            {id:16,adi:"Sezonlar", url:"/yonetici/sezonlar" },            
        ]
    },
    {
        id:20,
        adi : "Ülke Organizasyonlar",
        url:'#',
        ikon : <FaFootballBall size={24}/>,
        submenuvar : true,
        submenu :[
            {id:21,adi:"Ligler", url:"/yonetici/ligler" },
            {id:22,adi:"Yerel Kupalar", url:"/yonetici/ligler" },
        ]
    },
    {
        id:30,
        adi : "Uluslararası Organizasyonlar",
        url:'#',
        ikon : <FaTrophy size={24}/>,
        submenuvar : true,
        submenu :[
            {id:31,adi:"Takım Organizasyonları", url:"/yonetici/ligler" },
            {id:32,adi:"Milli Organizasyonlar", url:"/yonetici/ligler" },
        ]
    },
    {
        id:90,
        adi : "Yönetici İşlemleri",
        url:'#',
        ikon : <FaUser size={24}/>,
        submenuvar : true,
        submenu :[
            {id:91,adi:"Kullanıcılar", url:"/yonetici/kullanicilar" },
        ]
    },
];