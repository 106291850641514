import React,{ Fragment, useEffect, useState} from 'react';
import {FiAlignJustify} from 'react-icons/fi';
import {TbLogout} from 'react-icons/tb';
import {MdSettings,MdCancel,MdOutlineDeveloperMode,MdWarning} from 'react-icons/md';
import logo from '../assets/images/logo64.png';
import { useNavigate,Outlet} from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import '../assets/css/admin.css';
import {useStateContext} from '../contexts/ContextProvider';
import { useKullaniciAuth } from '../contexts/AdminAuthProvider';
import Modal from '../components/Modal';
import flag_en from '../assets/images/langflag/en.png';
import flag_es from '../assets/images/langflag/es.png';
import flag_de from '../assets/images/langflag/de.png';
import flag_tr from '../assets/images/langflag/tr.png';

const AdminLayout = () => {
  const navigate = useNavigate();
  const [kullaniciCikisModal,setKullaniciCikisModal] = useState(false);
  const {activeMenu,setActiveMenu,screenSize,setScreenSize,currentMode, setMode,currentLanguage,setCurrentLanguage,setLanguage} = useStateContext();
  const {kullanici,setKullanici} = useKullaniciAuth();
  const [dilDegistir,setDilDegistir] = useState(false);

  const dilDegistirClick = (dil) => {
    setLanguage(dil);
    setDilDegistir(false);
  }

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize',handleResize);
    handleResize();
    return () => window.removeEventListener('resize',handleResize);
  },[]);

  useEffect(() => {
    if(screenSize <= 900){
      setActiveMenu(false);
    }else {
      setActiveMenu(true);
    }
    setMode("Light");
  },[screenSize]);
  
  const kullaniciCikisClickHandle = () => {
      setKullanici(false);
      navigate('/yoneticigiris');
  }
  return (
    <div className='flex flex-row font-nunito'>
      <div className={`flex flex-col border-none  bg-white ${activeMenu ? "w-[300px] fixed z-50" : "w-0 relative"} "}`}>

          <div className='h-screen md:overflow-hidden overflow-auto border-r-2 border-r-[#ebf0fa]
                    md:hover:overflow-auto pb-10'>
            <div className={`flex flex-row ${screenSize<=900 ? "justify-between" : "justify-start"} w-full h-16 pt-2 pl-2 pr-3 items-center border-none`}>
                <button onClick={() => navigate('/yonetici/anasayfa')}>
                  <img src={logo} width="32" className="" alt='StatStand'/>
                </button>
                <button className='pl-2' onClick={() => navigate('/yonetici/anasayfa')}>
                  <span className='text-base font-bold text-black'>StatStand</span>
                </button>
                {screenSize <=900 && (
                  <button onClick={() => setActiveMenu(false)}>
                    <MdCancel fill='red' size={32} />
                  </button>
                )}
            </div>
            <div className='pt-4 pl-2'>
              <Sidebar />
            </div>

          </div>
      </div>
      <div className={`border-none w-full h-screen bg-main-content overflow-scroll ${activeMenu && screenSize > 900 ? "ml-[300px]" : ""}`}>
        <nav className='flex flex-row justify-between h-16 border-none'>
            <div className='flex flex-row justify-start items-center pl-4 pr-4'>
                <button onClick={() => setActiveMenu(!activeMenu)}>
                  <FiAlignJustify size={28} />
                </button>
                {/* <input type="text" className='bg-main-content ml-5 p-1 border border-white focus:border-2 text-sm focus:border-white rounded-md'/> */}
                
            </div>
            <div className='flex flex-row justify-end items-center pr-4'>
              {screenSize > 500 && (
                <span className='text-black'>{kullanici.AdSoyad}&nbsp;&nbsp;</span>
              )}
              <button className='rounded-full w-10 hover:bg-white justify-center items-center' onClick={() => {setDilDegistir(true)}}>
                {currentLanguage === "en" && (<img className='w-[30px] h-5' src={flag_en} alt='English' />)}
                {currentLanguage === "es" && (<img className='w-[30px] h-5' src={flag_es} alt='Spanish' />)}
                {currentLanguage === "de" && (<img className='w-[30px] h-5' src={flag_de} alt='German' />)}
                {currentLanguage === "tr" && (<img className='w-[30px] h-5' src={flag_tr} alt='Turkish' />)}
              </button>
              {dilDegistir && (
              <Modal Baslik="Dili Değiştir"
                KapatTusu={true}
                kapatFonksiyon={() => setDilDegistir(false)}
                bodyIsHtml={false}
                body={
                  <div className={`${screenSize > 800 ? "w-[300px]" : "w-[300px]"}`} >
                    <div onClick={()=> {dilDegistirClick("en")}} className='flex flex-row justify-start items-center my-2 bg-white hover:bg-gray-200 p-2 cursor-pointer rounded-lg'>
                      <img className='w-[60px] h-10' src={flag_en} alt='English' />
                      <span className='ml-3 font-bold'>İngilizce</span>                        
                    </div>
                    <div onClick={()=> {dilDegistirClick("es")}} className='flex flex-row justify-start items-center my-2 bg-white hover:bg-gray-200 p-2 cursor-pointer rounded-lg'>
                      <img className='w-[60px] h-10' src={flag_es} alt='Spanish' />
                      <span className='ml-3 font-bold'>İspanyolca</span>                        
                    </div>
                    <div onClick={()=> {dilDegistirClick("de")}} className='flex flex-row justify-start items-center my-2 bg-white hover:bg-gray-200 p-2 cursor-pointer rounded-lg'>
                      <img className='w-[60px] h-10' src={flag_de} alt='German' />
                      <span className='ml-3 font-bold'>Almanca</span>                        
                    </div>
                    <div onClick={()=> {dilDegistirClick("tr")}} className='flex flex-row justify-start items-center my-2 bg-white hover:bg-gray-200 p-2 cursor-pointer rounded-lg'>
                      <img className='w-[60px] h-10' src={flag_tr} alt='Turkish' />
                      <span className='ml-3 font-bold'>Türkçe</span>                        
                    </div>
                  </div>
                }

              />
              )}

{/* 


              {currentMode === 'Dark' ? (
                <button className='rounded-full w-9 hover:bg-white justify-center items-center'
                        onClick={() => setMode('Light')}>
                  <MdLightMode size={28} className="pl-2 " fill='white' />
                </button>
              ) : (
                <button className='rounded-full w-9 hover:bg-white justify-center items-center'
                        onClick={() => setMode('Dark')}>
                  <MdDarkMode size={28} className="pl-2" fill='white' />
                </button>
              )}
*/}
              <button className='rounded-full w-9 hover:bg-white justify-center items-center'
                        onClick={() => navigate('/yonetici/kullaniciayarlar')}>
                <MdSettings size={28} className="pl-2"  />
              </button>
              <button className='rounded-full w-9 hover:bg-white justify-center items-center'
                        onClick={() => setKullaniciCikisModal(true)}>
                <TbLogout size={28} className="pl-2"  />
              </button>
              {kullaniciCikisModal && (
                <Modal Baslik="Emin Misiniz ?"
                KapatTusu={true}
                kapatFonksiyon={() => setKullaniciCikisModal(false)}
                bodyIsHtml={true}
                body="Sistemden çıkmak istediğinize emin misiniz ?"
                footer={
                  <Fragment>
                    <button className='py-2 px-4 bg-blue-600 rounded-md text-white font-medium hover:bg-blue-900'
                    onClick={kullaniciCikisClickHandle}>Çıkış Yap</button>
                    <button className='py-2 px-4 bg-red-600 rounded-md text-white font-medium hover:bg-red-900'
                    onClick={() => setKullaniciCikisModal(false)}>İptal Et</button>
                  </Fragment>
                }
                />

              )}


              {process.env.NODE_ENV ==='development' ? (
                    <div className='ml-2 ysntooltip' data-tooltip="Geliştime Modu" data-left>
                      <MdOutlineDeveloperMode size={28} fill="green"/>
                    </div>
                  ) : (
                    <div className='ml-2 ysntooltip' data-tooltip="DİKKAT : Gerçek Ortam" data-left>
                      <MdWarning size={28} fill="red"/>
                    </div>                 
              )}
            </div>
        </nav>
        <div className='px-4 my-4 flex flex-col justify-start'>
          <Outlet/>
        </div>
        
        {screenSize >=500 && (
          <footer className='h-10 flex flex-row justify-between items-center px-4'>
            <span className='text-black'>2023 @StatStand</span>
            <span className='text-black'>Genişlik : {screenSize}px</span>
            <span className='text-black'>Tüm hakkı saklıdır</span>
          </footer>
        )}
      </div>
    </div>
  )
}

export default AdminLayout