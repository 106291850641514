import React,{ useState } from "react"
import TabloPagination from "./TabloPagination";

const PaginationDiv = ({isLoading,body,sayfalama,sayfaSayisi,bosVeriUyarisi}) => {
    const [currentPage,setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(sayfalama ? sayfaSayisi : 10000);
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentData = body.slice(indexOfFirstItem,indexOfLastItem)

    const totalPagesNum = Math.ceil(body.length / itemsPerPage);
    
    return (
        <>
            {currentData.length > 0 ? (
                <>
                {currentData}
                {sayfalama && (
                    <div className="w-full flex flex-row justify-between items-center mt-2">
                        <div className="">
                            <select defaultValue={itemsPerPage} onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}} className="w-[70px] p-2 border border-gray-500 rounded-md dark:bg-stone-700 dark:text-white">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                
                            </select>
                            <span className="ml-3 text-sm text-black dark:text-white">
                                {body.length} veriden {indexOfFirstItem + 1} ile {body.length < indexOfLastItem ? body.length : indexOfLastItem} arası veriler gösteriliyor !..
                            </span>
                        </div>
                        <div>
                            <TabloPagination pages={totalPagesNum} setCurrentPage={setCurrentPage} />
                        </div>
                    </div>
                )}
                </>
            ) : (
                !isLoading && (                
                <div className="w-full">
                    <div className='p-4 rounded bg-yellow-100 text-yellow-700 text-sm mb-5'>
                        
                        {bosVeriUyarisi==='' ? 'Aranan kriterlere göre veri bulunamadı !..' : bosVeriUyarisi}
                    </div>
                </div>)
            )}
        </>
    )
}
export default PaginationDiv