import React from 'react'
import {ImCross} from 'react-icons/im';
import parse from 'html-react-parser';

const Modal = (props) => {
  return (
    <div className="bg-zinc-400/70 fixed inset-0 z-50 max-h-screen 
        overflow-hidden overflow-y-scroll dark:bg-zinc-700/80">
        <div className="flex h-screen max-w-5xl m-auto justify-center items-center">
            <div className="bg-white rounded-lg shadow relative dark:bg-gray-700 z-10">                
                <div className="flex items-start justify-between px-5 pt-3 pb-2 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-gray-900 text-xl lg:text-2xl font-semibold dark:text-white">
                        {props.Baslik}
                    </h3>
                    {props.KapatTusu && (
                        <button onClick={props.kapatFonksiyon} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="default-modal">
                            <ImCross />
                        </button>
                    )}
                </div>
                <div className="p-6 space-y-6 ">
                    {props.bodyIsHtml ? (
                        <p className="text-gray-500 text-base leading-relaxed dark:text-gray-400">
                            {parse(props.body)}
                        </p> 
                    ) : (
                        props.body
                    )}
                </div>
                <div className="flex justify-end space-x-2 items-center px-6 py-3 border-t border-gray-200 rounded-b dark:border-gray-600">
                    {props.footer}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Modal