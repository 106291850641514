
import React,{createContext,useContext,useState} from "react";

const StateContext = createContext();

export const ContextProvider = ({children}) =>{
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en');
    const [currentMode, setCurrentMode] = useState(localStorage.getItem('themeMode') ? localStorage.getItem('themeMode') :'Light');
    const [themeSettings, setThemeSettings] = useState(false);
    const [activeMenu, setActiveMenu] = useState(true);
   
    const setMode = (mode) => {
        setCurrentMode(mode);
        localStorage.setItem('themeMode', mode);
        setThemeSettings(false);
      };
    
      const setLanguage = (lang) => {
        setCurrentLanguage(lang);
        localStorage.setItem('lang', lang);
        setThemeSettings(false);
      };


    return (
        <StateContext.Provider 
            value={{
                    screenSize, 
                    setScreenSize, 
                    currentLanguage, 
                    setCurrentLanguage, 
                    currentMode, 
                    setCurrentMode,
                    themeSettings, 
                    setThemeSettings,
                    activeMenu, 
                    setActiveMenu,                   
                    
                    setMode, 
                    setLanguage
                   
            }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);