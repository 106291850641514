import React,{useState,Fragment,useEffect} from 'react';
import { ToastContainer,toast } from 'react-toastify';
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import WrapRow from '../../components/designs/WrapRow';
import Card12 from '../../components/designs/Card12';
import Kriter03 from '../../components/designs/Kriter03'
import KriterWrap from '../../components/designs/KriterWrap'
import LoadingButton from '../../components/LoadingButton';
import Modal from '../../components/Modal';
import {useStateContext} from '../../contexts/ContextProvider';
import { SezonlarService, UlkelerService } from '../../services';
import PaginationDiv from '../../components/PaginationDiv';

const Ulkeler = () => {
    const {screenSize} = useStateContext();
    const [isLoading,setIsLoading] = useState(false);
    const [kita,setKita] = useState('TÜMÜ');
    const [ara,setAra] = useState('');
    const [kategori,setKategori] = useState('TÜMÜ');
    const [kitalar,setKitalar] = useState([]);
    const [ulkeler,setUlkeler] = useState([]);
    const [ulkeGuncelle,setUlkeGuncelle] = useState({UlkeId:0,UlkeAdi:'',BayrakYol:'',Kita:'',SezonKategori:''});
    const [ulkeGuncelleModal,setUlkeGuncelleModal] = useState(false);
    const [kategoriler,setKategoriler] = useState([]);

    const ulkeGuncelleChangeEvent = (e) => {
        setUlkeGuncelle({...ulkeGuncelle,[e.target.name]:e.target.value});
    }
    const ulkeGuncelleAc = (ulkeid) => {
        setIsLoading(true);
        UlkelerService.UlkeIdyeGoreGetir(ulkeid)
        .then(response => {
            setIsLoading(false);
            setUlkeGuncelle(response);
            setUlkeGuncelleModal(true);
        });
    }

    const guncelleClick = () => {
        setIsLoading(true);
        UlkelerService.UlkeGuncelle(ulkeGuncelle)
        .then(response => {
            setIsLoading(false);
            if(response.Sonuc){
                toast.info(response.SonucAciklama, {position: "top-right",autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
                setUlkeGuncelle({UlkeId:0,UlkeAdi:'',BayrakYol:'',Kita:''});
                setUlkeGuncelleModal(false);
                ulkeleriDoldur();
            }else{
                toast.danger(response.SonucAciklama, {position: "top-right",autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            }
        });
    }

    const ulkeleriDoldur = () => {
        setIsLoading(true);
        UlkelerService.AramayaGoreGetir(kita,ara,kategori)
        .then(response => {
            setIsLoading(false);
            setUlkeler(response);
        });
    }

    const kitalariDoldur = () => {
        setIsLoading(true);
        UlkelerService.KitalariGetir()
        .then(response => {
            setIsLoading(false);
            setKitalar(response);
        });
    }

    const sezonKategorileriDoldur = () => {
        setIsLoading(true);
        SezonlarService.SezonKategorileriGetir()
        .then(response => {
            setIsLoading(false);
            setKategoriler(response);
        });
    }

    useEffect(() => {
        kitalariDoldur();
        ulkeleriDoldur();
        sezonKategorileriDoldur();
    },[]);
    return (
    <>
        <ToastContainer/>
        <AdminLayoutPageHeader title="Ülkeler"/>
        <WrapRow isLoading={isLoading}>
            <Card12>
                <KriterWrap>
                    <Kriter03 title="Kıta :">
                        <select disabled={isLoading} value={kita} className={`border border-gray-700 p-2 rounded-md mb-2 `} 
                            onChange={(e) => {setKita(e.target.value)}}>
                            <option value="TÜMÜ">Tüm Kıtalar</option>
                            {kitalar.map((cb,index) => (
                                <option key={index} value={cb}>{cb}</option>
                            ))}
                        </select>
                    </Kriter03>
                    <Kriter03 title="Sezon Kategori :">
                        <select disabled={isLoading} value={kategori} className={`border border-gray-700 p-2 rounded-md mb-2 `} 
                            onChange={(e) => {setKategori(e.target.value)}}>
                            <option value="TÜMÜ">Tümü</option>
                            {kategoriler.map((cb,index) => (
                                <option key={index} value={cb}>{cb}</option>
                            ))}
                        </select>
                    </Kriter03>
                    <Kriter03 title="Ara :">
                    <input disabled={isLoading} 
                        className={`border border-gray-700 p-2 rounded-md mb-2 `} 
                        onChange={(e) => {setAra(e.target.value)}} 
                        type="text" placeholder='Arama yapın' autoComplete='off' />
                    </Kriter03>
                    <Kriter03 title="&nbsp;">
                        <LoadingButton size={100} renk="indigo" onClick={ulkeleriDoldur} title="Ara" loading={isLoading}/>
                    </Kriter03>
                </KriterWrap>
                <div className='p-4 mx-1 my-2 bg-yellow-100 border border-yellow-500 text-yellow-500 rounded-lg font-bold text-md'>
                    Toplam {ulkeler.length} ülke listelenmiştir !..
                </div>
                <div className='flex flex-wrap justify-start'>
                    <PaginationDiv isLoading={isLoading} bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.." sayfalama={true} sayfaSayisi={20}
                        body={ulkeler.map(veri => (
                            <div key={veri.UlkeId} onClick={() => {ulkeGuncelleAc(veri.UlkeId)}}
                                className='flex flex-row justify-start items-center  w-1/2 p-1 cursor-pointer'>
                                <div className={`p-4 flex flex-row justify-start items-center rounded-md w-full border border-stone-800  ${veri.SezonKategori === 'Yaz Sezonu' ? "bg-red-50 hover:bg-red-200" : "bg-blue-50 hover:bg-blue-200"}`}>
                                    <img className='w-[70px] h-10' src={process.env.REACT_APP_API_URL + veri.BayrakYolURL}/>
                                    <div className='flex flex-col justify-center'>
                                        <span className='ml-5 font-bold text-black'>{veri.UlkeAdi}</span>
                                        <span className='ml-5 font-light text-sm text-black'>{veri.Kita}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    />
                </div>
            </Card12>            
        </WrapRow>
        {ulkeGuncelleModal && (
            <Modal Baslik="Ülke Güncelle"
            KapatTusu={true}
            kapatFonksiyon={() => setUlkeGuncelleModal(false)}
            bodyIsHtml={false}
            body={
                <Fragment>
                <div className={`${screenSize > 800 ? "w-[700px]" : "w-[300px]"}`} >
                    <div className='flex flex-row justify-between items-center mb-1'>
                        <span className='w-1/5'>Kıta :</span>
                        <input name='UlkeAdi' value={ulkeGuncelle.UlkeAdi} className={`w-4/5 border border-gray-700 p-2 rounded-md`} 
                        autoComplete='off' onChange={ulkeGuncelleChangeEvent}/>
                    </div>
                    <div className='flex flex-row justify-between items-center mb-1'>
                        <span className='w-1/5'>Bayrak :</span>
                        <input name='BayrakYol' value={ulkeGuncelle.BayrakYol} className={`w-4/5 border border-gray-700 p-2 rounded-md`} 
                        autoComplete='off' onChange={ulkeGuncelleChangeEvent}/>
                    </div>
                    <div className='flex flex-row justify-between items-center mb-1'>
                        <span className='w-1/5'>Kıta :</span>
                        <select name='Kita' value={ulkeGuncelle.Kita} className={`w-4/5 border border-gray-700 p-2 rounded-md`} 
                            onChange={ulkeGuncelleChangeEvent}>
                            <option value="Seçiniz">Seçiniz</option>
                            {kitalar.map((cb,index) => (
                                <option key={index} value={cb}>{cb}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-row justify-between items-center mb-1'>
                        <span className='w-1/5'>Sezon Kategori :</span>
                        <select name='SezonKategori' value={ulkeGuncelle.SezonKategori} className={`w-4/5 border border-gray-700 p-2 rounded-md`} 
                            onChange={ulkeGuncelleChangeEvent}>
                            <option value="Seçiniz">Seçiniz</option>
                            {kategoriler.map((cb,index) => (
                                <option key={index} value={cb}>{cb}</option>
                            ))}
                        </select>
                    </div>
                </div>
                </Fragment>
            }
            footer={
                <Fragment>
                <button className='py-2 px-4 bg-green-700 rounded-md text-white font-medium hover:bg-green-900'
                onClick={guncelleClick}>Güncelle</button>
                <button className='py-2 px-4 bg-gray-700 rounded-md text-white font-medium hover:bg-gray-900'
                onClick={() => setUlkeGuncelleModal(false)}>Kapat</button>
                </Fragment>
            }
            />
        )}

    </>
    )
}

export default Ulkeler