import { get, postJSON } from './request';

export const TakimlarListesiniGetir = (UlkeId,Durum,Ara) => get(`Takimlar/TakimlarListesiniGetir?UlkeId=${UlkeId}&Durum=${Durum}&Ara=${Ara}`)
export const TakimIdyeGoreGetir = (TakimId) => get(`Takimlar/TakimIdyeGoreGetir?TakimId=${TakimId}`)
export const TakimEkle = (veri) => postJSON(`Takimlar/TakimEkle`,veri)
export const TakimBilgiGuncelle = (veri) => postJSON(`Takimlar/TakimBilgiGuncelle`,veri)
export const TakimDurumGuncelle = (veri) => postJSON(`Takimlar/TakimDurumGuncelle`,veri)

export const TakimLogoDegistir = (dosya,TakimId,basariliDosyaEklemeEvent) => {
    var formData = new FormData();
    formData.append('file',dosya);
    fetch(process.env.REACT_APP_API_URL + 'Takimlar/TakimLogoDegistir?TakimId='+TakimId, {
      method: 'POST', body: formData,
    })
      .then((res) => res.json())
      .then((data) => { basariliDosyaEklemeEvent()})
      .catch((err) => console.error(err));
}