import React from 'react'

const KriterWrap = (props) => {
  return (
    <div className={`rounded-2xl mt-2 px-4 py-2 flex flex-wrap`}>
        {props.children}
    </div>
  )
}

export default KriterWrap