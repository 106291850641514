import {React,useState,Fragment,useRef} from 'react'
import { ToastContainer,toast} from 'react-toastify'
import WrapRow from '../../components/designs/WrapRow'
import Card12 from '../../components/designs/Card12'
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import KriterWrap from '../../components/designs/KriterWrap'
import Kriter03 from '../../components/designs/Kriter03'
import Kriter12 from '../../components/designs/Kriter12'
import LoadingButton from '../../components/LoadingButton'
import Button from '../../components/Button'
import { useEffect } from 'react';
import {  StadyumlarService, TakimlarService, UlkelerService } from '../../services';
import TabloUfakYazi from '../../components/TabloUfakYazi';
import { useKullaniciAuth } from '../../contexts/AdminAuthProvider';
import Modal from '../../components/Modal';
import noimage from '../../assets/images/noimage.png';
import PaginationDiv from '../../components/PaginationDiv';
import { useStateContext } from '../../contexts/ContextProvider';

const Takimlar = () => {
  const {screenSize} = useStateContext();
    const {kullanici} = useKullaniciAuth();
    const [isLoading,setIsLoading] = useState(false);
    const [kriterler,setKriterler] = useState({UlkeId:0,Durum:2,Ara:''});
    const [ulkeler,setUlkeler] = useState([]);
    const [stadyumlar,setStadyumlar] = useState([]);
    const [tabloVeriler,setTabloVeriler] = useState([]);
    const [takimEkleModal,setTakimEkleModal] = useState(false);
    const [takimEkle,setTakimEkle] = useState({TakimAdi:'',UzunIsim:'',KisaIsim:'',UlkeId:0,StadyumId:0,KurulusYil:0,KayitKullaniciId:kullanici.KullaniciId});
    const [stadyumVeriler,setStadyumVeriler] = useState([]);
    const inputRef = useRef(null);
    const [image,setImage] = useState(null);

    const inputGuncelleRef = useRef(null);
    const [imageGuncelle,setImageGuncelle] = useState(null);
    const [takimGuncelle,setTakimGuncelle] = useState(false);
    const [takimGuncelleModal,setTakimGuncelleModal] = useState(false);

    const handleAraClick = () => {
      setIsLoading(true);
      TakimlarService.TakimlarListesiniGetir(kriterler.UlkeId,kriterler.Durum,kriterler.Ara)
      .then(response => {           
          setIsLoading(false);
          setTabloVeriler(response);          
      });
    }

    const araKeyDownEvent = (e) => {
      if(e.key === "Enter"){        
        handleAraClick();        
      }
    }

    const araKriterChangeHandle = (e) => {
        setKriterler({...kriterler,[e.target.name]:e.target.value});
    }

    const ulkeChangeEvent = (ulkeid) => {
      if(ulkeid !== 0 && ulkeid !== '0'){
        setKriterler({...kriterler,UlkeId:ulkeid});
        StadyumlarService.StadyumlarListesiniGetir(ulkeid,0,1,"")
        .then(response => {
          setStadyumlar(response);
        });
      }else{
        setKriterler({...kriterler,UlkeId:0});
        setStadyumlar([]);
      }
    }

    const takimEkleChangeHandle = (e) => {
      setTakimEkle({...takimEkle,[e.target.name]:e.target.value});
    }

    const takimEkleUlkeChangeHandle = (e) => {
      let ulkeid = e.target.value;
      setTakimEkle({...takimEkle,UlkeId:ulkeid});

      if(ulkeid === 0 || ulkeid === '0'){
        setStadyumVeriler([]);
      }else{
        StadyumlarService.StadyumlarListesiniGetir(ulkeid,0,1,"")
        .then(response => {
          setStadyumVeriler(response);
        });
      }
    }

    const basariliDosyaEklemeEvent = () => {
      toast.success("Takım için logo başarılı bir şekilde yüklendi !..", {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"});
      handleAraClick();
    }
    const takimEkleClick = () => {
      setIsLoading(true);
      TakimlarService.TakimEkle(takimEkle)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          if(image !== null){
            TakimlarService.TakimLogoDegistir(image,response.DonenID,basariliDosyaEklemeEvent);
            toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            setTakimEkle({TakimAdi:'',UzunIsim:'',KisaIsim:'',UlkeId:0,StadyumId:0,KurulusYil:0,KayitKullaniciId:kullanici.KullaniciId});
            setTakimEkleModal(false);
            setImage(null);
            
          }else{
            toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            setTakimEkle({TakimAdi:'',UzunIsim:'',KisaIsim:'',UlkeId:0,StadyumId:0,KurulusYil:0,KayitKullaniciId:kullanici.KullaniciId});
            setTakimEkleModal(false);
            setImage(null);
            handleAraClick();
          }
          

        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });

    }

    const handleImageClick = () => {
      inputRef.current.click();
    }

    const handleImageChange = (e) => {
      setImage(e.target.files[0]);
    }

    const handleImageGuncelleClick = () => {
      inputGuncelleRef.current.click();
    }
  
    const handleImageGuncelleChange = (e) => {
      setImageGuncelle(e.target.files[0]);
    }

    const takimGuncelleAc = (takimid) => {
      setIsLoading(true);
      TakimlarService.TakimIdyeGoreGetir(takimid)
      .then(response => {
        setIsLoading(false);
        setTakimGuncelle(response);
        setTakimGuncelleModal(true);
        StadyumlarService.StadyumlarListesiniGetir(response.UlkeId,0,1,"")
        .then(response => {
          setStadyumVeriler(response);
        });
      });
    }

    const takimGuncelleChangeHandle = (e) => {
      setTakimGuncelle({...takimGuncelle,[e.target.name]:e.target.value});
    }

    const takimGuncelleUlkeChangeHandle = (e) => {
      let ulkeid = e.target.value;
      setTakimGuncelle({...takimGuncelle,UlkeId:ulkeid});

      if(ulkeid === 0 || ulkeid === '0'){
        setStadyumVeriler([]);
      }else{
        StadyumlarService.StadyumlarListesiniGetir(ulkeid,0,1,"")
        .then(response => {
          setStadyumVeriler(response);
        });
      }
    }

    const takimBilgiGuncelleClick = () => {
      setIsLoading(true);
      TakimlarService.TakimBilgiGuncelle(takimGuncelle)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          if(imageGuncelle != null){
            TakimlarService.TakimLogoDegistir(imageGuncelle,takimGuncelle.TakimId,basariliDosyaEklemeEvent);
          }
          setImageGuncelle(null);
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setTakimGuncelle(false);
          setTakimGuncelleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });
    }

    const takimDurumDegistir = (aktif) => {
      let veri = {TakimId:takimGuncelle.TakimId,Aktif:aktif,AktifKullaniciId:kullanici.KullaniciId};
      setIsLoading(true);
      TakimlarService.TakimDurumGuncelle(veri)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setTakimGuncelle(false);
          setTakimGuncelleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });
    }

    useEffect(() => {      
        setIsLoading(true);        
        UlkelerService.AramayaGoreGetir("TÜMÜ","","TÜMÜ")
        .then(response => {
          setIsLoading(false);
          setUlkeler(response);
          setStadyumlar([]);
          handleAraClick();
        });        
    },[]);
    return (
    <>
      <ToastContainer/>
      <AdminLayoutPageHeader title="Takımlar" subtitle="" />
      <WrapRow isLoading={isLoading}>
        <Card12>
          <Kriter12>
              <Button renk="green" onClick={() => {setTakimEkleModal(true)}}>Takım Ekle</Button>
          </Kriter12>
          <KriterWrap>
            <Kriter03 title="Ülke :">
              <select value={kriterler.UlkeId} disabled={isLoading} name="UlkeId"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={(e) => {ulkeChangeEvent(e.target.value)}}>
                <option value={0}>TÜMÜ</option>
                {ulkeler.map(item => (
                  <option key={item.UlkeId} value={item.UlkeId}>{item.UlkeAdi}</option>
                ))}
              </select>
            </Kriter03>
            <Kriter03 title="Durum :">
              <select value={kriterler.Durum} disabled={isLoading} name="Durum"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={araKriterChangeHandle}>
                <option value={2}>Tümü</option>
                <option value={1}>Aktif Takımlar</option>
                <option value={0}>Pasif Takımlar</option>
              </select>
            </Kriter03>          
            <Kriter03 title="Ara :">
              <input disabled={isLoading} name="Ara"
                     className={`border border-gray-700 p-2 rounded-md mb-2`} 
                     onChange={araKriterChangeHandle}                     
                     onKeyDown={araKeyDownEvent} 
                     value={kriterler.Ara}
                     type="text" 
                     autoComplete='off'
                     placeholder='Arama yapın'  />
            </Kriter03>
            <Kriter03 title={`Toplam Veri : `+ tabloVeriler.length}>
              <LoadingButton size={100} renk="indigo" onClick={handleAraClick} title="Ara" loading={isLoading}/>
            </Kriter03>
          </KriterWrap>
          {screenSize > 1200 ? (
          <TabloUfakYazi isLoading={isLoading} bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.." sayfalama={true} sayfaSayisi={20}
              head={[
                      {name : 'Logo',sortable:false,sayi:false},
                      {name : 'Takım Adi',sortable:true},
                      {name : 'Aktif Lig',sortable:true},
                      {name : 'Kuruluş Yıl',sortable:true,sayi:true},
                      {name : 'Ülke',sortable:true,sayi:false},
                      {name : 'Stadyum',sortable:true,sayi:false},
                      {name : 'İşlemler',sortable:false,islem:true},
                  ]} 
              body={tabloVeriler.map(veri => ([
                  [
                  <div key={veri.TakimId} className="flex flex-row justify-center">
                      <img src={process.env.REACT_APP_API_URL + veri.LogoYolURL}  className='w-[60px] h-30 object-contain bg-white rounded-lg p-2' alt={veri.TakimAdi} />
                  </div>
                  ],
                  veri.TakimAdi,
                  '???',
                  veri.KurulusYil,
                  [                  
                  <div key={veri.TakimId} className="flex flex-row justify-center items-center">
                      <img src={process.env.REACT_APP_API_URL + veri.UlkeBayrakYolURL}  className='w-[60px] h-30 object-contain bg-white rounded-lg p-2' alt={veri.UlkeAdi} />
                      <span className='ml-3'>{veri.UlkeAdi}</span>
                  </div>
                  ],
                  veri.StadyumAdi,                 
                  [
                      <div key={veri.TakimId} className="flex flex-row justify-end">
                          <div className=''>
                              <Button renk="orange" onClick={() =>{takimGuncelleAc(veri.TakimId)}}>Güncelle</Button>
                          </div>
                      </div>
                  ]
              ]))}
          />  
          ) : (
            <PaginationDiv 
              isLoading={isLoading}
              bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.."
              sayfalama={true} sayfaSayisi={20}
              body={tabloVeriler.map(veri => (
                <div key={veri.TakimId} onClick={() =>{takimGuncelleAc(veri.TakimId)}}
                 className='flex flex-row justify-start w-full h-[80px] border border-gray-800 rounded-md mb-2 cursor-pointer'>
                  <div className='flex flex-row justify-center items-center m-2'>
                    <img src={process.env.REACT_APP_API_URL + veri.LogoYolURL}  className='w-[60px] h-14 object-contain bg-white rounded-lg p-2' alt={veri.TakimAdi} />
                  </div>
                  <div className='flex flex-col justify-start m-2'>
                    <span>{veri.TakimAdi}</span>
                    <div className='flex flex-row justify-start items-center'>
                      <img src={process.env.REACT_APP_API_URL + veri.UlkeBayrakYolURL}  className='w-[40px] h-10 object-contain bg-white rounded-lg p-2' alt={veri.UlkeAdi} />
                      <span className='ml-1'>{veri.UlkeAdi}</span>
                    </div>
                  </div>
                    
                </div>
              ))}
            />
          )}
     
        </Card12>       
      </WrapRow>


      {takimEkleModal && (
          <Modal
          Baslik="Yeni Takım Ekle"
          KapatTusu={true}
          kapatFonksiyon={() => setTakimEkleModal(false)}
          bodyIsHtml={false}
          body={
              <div className={`${screenSize > 1200 ? "w-[600px]" : "w-[300px]"}  flex flex-col justify-start`}>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Takım Adı :</span>
                      <input disabled={isLoading} name="TakimAdi" value={takimEkle.TakimAdi} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={takimEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Takım Uzun İsim :</span>
                      <input disabled={isLoading} name="UzunIsim" value={takimEkle.UzunIsim} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={takimEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Takım Kısa İsim :</span>
                      <input disabled={isLoading} name="KisaIsim" value={takimEkle.KisaIsim} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={takimEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Kuruluş Yıl :</span>
                      <input disabled={isLoading} name="KurulusYil" value={takimEkle.KurulusYil} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="number" autoComplete='off' 
                        onChange={takimEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Ülke :</span>
                      <select name='UlkeId' disabled={isLoading} value={takimEkle.UlkeId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={takimEkleUlkeChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Stadyum :</span>
                      <select name='StadyumId' disabled={isLoading} value={takimEkle.StadyumId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={takimEkleChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {stadyumVeriler.map((stadyum) => (
                          <option key={stadyum.StadyumId} value={stadyum.StadyumId}>{stadyum.StadyumAdi}</option>
                        ))}
                    </select>
                  </div>
                  <div className='flex flex-row justify-between items-start mb-2'>
                    <span className='w-1/4 mt-4'>Resim :</span>
                    <div className='w-3/4 flex flex-col justify-start items-start cursor-pointer' onClick={handleImageClick}>
                      
                      {image ? (<img src={URL.createObjectURL(image)}  className='w-1/2' alt='Yeni Ürün Resim' />) :(<img src={noimage}  className='w-[100px] h-30 object-contain bg-white rounded-lg p-2' alt='Yeni Ürün Resim' />)}
                      <input type='file' ref={inputRef} style={{display:"none"}}
                        onChange={handleImageChange}/>
                    </div>

                  </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={takimEkleClick} title="Ekle" loading={isLoading}/>
                  <Button renk="white" onClick={() => setTakimEkleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}


      {takimGuncelleModal && (
          <Modal
          Baslik="Takım Güncelle"
          KapatTusu={true}
          kapatFonksiyon={() => setTakimGuncelleModal(false)}
          bodyIsHtml={false}
          body={
              <div className={`${screenSize > 1200 ? "w-[600px]" : "w-[300px]"}  flex flex-col justify-start`}>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Takım Adı :</span>
                      <input disabled={isLoading} name="TakimAdi" value={takimGuncelle.TakimAdi} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={takimGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Takım Uzun İsim :</span>
                      <input disabled={isLoading} name="UzunIsim" value={takimGuncelle.UzunIsim} 
                        className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={takimGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Takım Kısa İsim :</span>
                      <input disabled={isLoading} name="KisaIsim" value={takimGuncelle.KisaIsim} 
                        className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={takimGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Kuruluş Yıl :</span>
                      <input disabled={isLoading} name="KurulusYil" value={takimGuncelle.KurulusYil} 
                        className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} type="number" autoComplete='off' 
                        onChange={takimGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Ülke :</span>
                      <select name='UlkeId' disabled={isLoading} value={takimGuncelle.UlkeId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={takimGuncelleUlkeChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Stadyum :</span>
                      <select name='StadyumId' disabled={isLoading} value={takimGuncelle.StadyumId} 
                          className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} 
                          onChange={takimGuncelleChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {stadyumVeriler.map((stadyum) => (
                          <option key={stadyum.StadyumId} value={stadyum.StadyumId}>{stadyum.StadyumAdi}</option>
                        ))}
                    </select>
                  </div>
                  <div className='flex flex-row justify-between items-start mb-2'>
                  <span className='w-1/4 mt-4'>Resim :</span>
                  <div className='w-3/4 flex flex-col justify-start items-start cursor-pointer' onClick={handleImageGuncelleClick}>                    
                    {imageGuncelle ? (<img src={URL.createObjectURL(imageGuncelle)}  className='w-1/2' alt='Ürün Resim Güncelle' />) :(<img src={process.env.REACT_APP_API_URL + takimGuncelle.LogoYolURL}  className='w-[200px] h-30 object-contain bg-white rounded-lg p-2' alt='Ürün Resim Güncelle'/>)}
                    <input type='file' ref={inputGuncelleRef} style={{display:"none"}}
                      onChange={handleImageGuncelleChange}/>
                  </div>
                </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={takimBilgiGuncelleClick} title="Güncelle" loading={isLoading}/>
                  {takimGuncelle.Aktif ? (
                    <LoadingButton renk="red" onClick={() => {takimDurumDegistir(false)}} title="Pasifleştir" loading={isLoading}/>
                  ) : (
                    <LoadingButton renk="teal" onClick={() => {takimDurumDegistir(true)}} title="Aktifleştir" loading={isLoading}/>
                  )}
                  
                  
                  <Button renk="white" onClick={() => setTakimGuncelleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}

    </>
    )
}

export default Takimlar