import React from 'react';
import AdminLayoutCardBaslik from '../../layouts/AdminLayoutCardBaslik';
import { useStateContext } from '../../contexts/ContextProvider';

const Card06 = (props) => {
  const { screenSize} = useStateContext();
  return (
    <div className={`bg-white ${screenSize >=1300 ? "w-1/2" : "w-full"} rounded-2xl mt-2 px-4 py-2 ${props.noborder ? "border-0" : "border-4 border-[#f2f7ff]"}`}>
        <AdminLayoutCardBaslik title={props.title} />
        {props.children}
    </div>
  )
}

export default Card06