import React from 'react';
import { Helmet } from 'react-helmet';

const AdminLayoutPageHeader = (props) => {
  return (
    <>
        <Helmet>
            <title>StatStand - {props.title}</title>
            <meta name="description" content={`Stat Stand`} />
        </Helmet>
        {props.title && (
            <div>
                <span className='font-black text-2xl pl-4 '>{props.title}</span>
            </div>
        )}
        {props.subtitle && (
            <div>
                <span className='font-base text-sm pl-4'>{props.subtitle}</span>
            </div>
        )}
    </>
  )
}

export default AdminLayoutPageHeader