import {React,useState,Fragment,useRef} from 'react'
import { ToastContainer,toast} from 'react-toastify'
import WrapRow from '../../components/designs/WrapRow'
import Card12 from '../../components/designs/Card12'
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import KriterWrap from '../../components/designs/KriterWrap'
import Kriter03 from '../../components/designs/Kriter03'
import Kriter12 from '../../components/designs/Kriter12'
import LoadingButton from '../../components/LoadingButton'
import Button from '../../components/Button'
import { useEffect } from 'react';
import { UlkelerService,KisilerService, SehirlerService } from '../../services';
import { useKullaniciAuth } from '../../contexts/AdminAuthProvider';
import Modal from '../../components/Modal';
import noimage from '../../assets/images/noimage.png';
import PaginationDiv from '../../components/PaginationDiv';
import { useStateContext } from '../../contexts/ContextProvider';
import DatePicker from 'react-datepicker';
import tr from 'date-fns/locale/tr';
import "react-datepicker/dist/react-datepicker.css";
import { TbSun, TbTrash } from 'react-icons/tb';
import { BiCalendar } from 'react-icons/bi';
import {  MdLocationPin } from 'react-icons/md';

const Kisiler = () => {
    const {screenSize} = useStateContext();
    const {kullanici} = useKullaniciAuth();
    const [isLoading,setIsLoading] = useState(false);
    const [kriterler,setKriterler] = useState({UlkeId:0,Durum:2,Ara:''});
    const [ulkeler,setUlkeler] = useState([]);    
    const [tabloVeriler,setTabloVeriler] = useState([]);
    const [kisiEkleModal,setKisiEkleModal] = useState(false);
    const [kisiEkle,setKisiEkle] = useState({KisiTamAd:'',KisiAd:'',KisiSoyad:'',DogumTarihString:'01.01.1900',DogumUlkeId:0,DogumSehirId:0,KayitKullaniciId:kullanici.KullaniciId,KisiUyruklar:[]});    
    const inputRef = useRef(null);
    const [image,setImage] = useState(null);
    const inputGuncelleRef = useRef(null);
    const [imageGuncelle,setImageGuncelle] = useState(null);
    const [kisiGuncelle,setKisiGuncelle] = useState(false);
    const [kisiGuncelleModal,setKisiGuncelleModal] = useState(false);
    const [tarihEkle,setTarihEkle] = useState(new Date());
    const [ekleDogumTarihAcKapa,setEkleDogumTarihAcKapa] = useState(false);
    const [tarihGuncelle,setTarihGuncelle] = useState(new Date());
    const [guncelleDogumTarihAcKapa,setGuncelleDogumTarihAcKapa] = useState(false);
    const [ekleUyruk,setEkleUyruk] = useState({UlkeId:0,AnaUyruk:false});
    const [sehirler,setSehirler] = useState([]);

    const ekleUyrukChange = (e) => {
        if(e.target.name === "AnaUyruk"){
            setEkleUyruk({...ekleUyruk,AnaUyruk:e.target.checked});
        }else{
            setEkleUyruk({...ekleUyruk,UlkeId:e.target.value});
        }
        
    }

    const handleAraClick = () => {
      setIsLoading(true);
      KisilerService.KisilerListesiniGetir(kriterler.UlkeId,kriterler.Durum,kriterler.Ara)
      .then(response => {           
          setIsLoading(false);
          setTabloVeriler(response);          
      });
    }

    const araKeyDownEvent = (e) => {
      if(e.key === "Enter"){        
        handleAraClick();        
      }
    }

    const ulkeChangeEvent = (ulkeid) => {
      setKriterler({...kriterler,UlkeId:ulkeid});
    }

    const araKriterChangeHandle = (e) => {
        setKriterler({...kriterler,[e.target.name]:e.target.value});
    }

    const kisiEkleChangeHandle = (e) => {
      setKisiEkle({...kisiEkle,[e.target.name]:e.target.value});
    }

    const kisiEkleUlkeChangeHandle = (e) => {
        let ulkeid = parseInt(e.target.value);
        setKisiEkle({...kisiEkle,[e.target.name]:e.target.value});
        setIsLoading(true);
        SehirlerService.SehirlerListesiniGetir(ulkeid,1,"")
        .then(response => {
            setIsLoading(false);
            setSehirler(response);
        })
    }

    const kisiGuncelleUlkeChangeHandle = (e) => {
      let ulkeid = parseInt(e.target.value);
      setKisiGuncelle({...kisiGuncelle,[e.target.name]:e.target.value});
      setIsLoading(true);
      SehirlerService.SehirlerListesiniGetir(ulkeid,1,"")
      .then(response => {
          setIsLoading(false);
          setSehirler(response);
      })
    }

    const basariliDosyaEklemeEvent = () => {
      toast.success("Kişi için resim başarılı bir şekilde yüklendi !..", {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light"});
      handleAraClick();
    }

    const kisiEkleClick = () => {
      setIsLoading(true);
      let yeniEkle = {KisiTamAd:kisiEkle.KisiAd + ' '+ kisiEkle.KisiSoyad,KisiAd:kisiEkle.KisiAd,KisiSoyad:kisiEkle.KisiSoyad,DogumTarihString:ekleDogumTarihAcKapa ? tarihEkle.toISOString().substring(0,10) : "1900-01-01",DogumUlkeId:kisiEkle.DogumUlkeId,DogumSehirId:kisiEkle.DogumSehirId,KayitKullaniciId:kullanici.KullaniciId,KisiUyruklar:kisiEkle.KisiUyruklar};
      
      KisilerService.KisiEkle(yeniEkle)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          if(image !== null){
            KisilerService.KisiResimGuncelle(image,response.DonenID,basariliDosyaEklemeEvent);
            toast.success(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            setKisiEkle({KisiTamAd:'',KisiAd:'',KisiSoyad:'',DogumTarihString:'01.01.1900',DogumUlkeId:0,DogumSehirId:0,KayitKullaniciId:kullanici.KullaniciId,KisiUyruklar:[]});
            setKisiEkleModal(false);
            setImage(null);
            setEkleDogumTarihAcKapa(false);
            setTarihEkle(new Date());
          }else{
            toast.success(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            setKisiEkle({KisiTamAd:'',KisiAd:'',KisiSoyad:'',DogumTarihString:'01.01.1900',DogumUlkeId:0,DogumSehirId:0,KayitKullaniciId:kullanici.KullaniciId,KisiUyruklar:[]});
            setKisiEkleModal(false);
            setImage(null);
            handleAraClick();
            setTarihEkle(new Date());
            setEkleDogumTarihAcKapa(false);
          }
          

        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });

    }

    const handleImageClick = () => {
      inputRef.current.click();
    }

    const handleImageChange = (e) => {
      setImage(e.target.files[0]);
    }

    const handleImageGuncelleClick = () => {
      inputGuncelleRef.current.click();
    }
  
    const handleImageGuncelleChange = (e) => {
      setImageGuncelle(e.target.files[0]);
    }

    const kisiGuncelleAc = (kisiid) => {
      setIsLoading(true);
      setImageGuncelle(null);
      KisilerService.KisiIdyeGoreGetir(kisiid)
      .then(response => {
        
        setKisiGuncelle(response);
        setKisiGuncelleModal(true);
        
        setGuncelleDogumTarihAcKapa(response.Yas !== 0);
        
        let my_date = new Date(response.DogumTarihString2.split(".").reverse().join("-"));
        setTarihGuncelle(my_date);
        if(response.DogumUlkeId === 0){
          setIsLoading(false);
          setSehirler([]);
        }else{
          SehirlerService.SehirlerListesiniGetir(response.DogumUlkeId,1,"")
          .then(responses => {
            setIsLoading(false);
            setSehirler(responses);
          });
        }
      });
    }

    const kisiGuncelleChangeHandle = (e) => {
      setKisiGuncelle({...kisiGuncelle,[e.target.name]:e.target.value});
    }

    const kisiBilgiGuncelleClick = () => {
      setIsLoading(true);
      let yeniGuncelle = { KisiId:kisiGuncelle.KisiId,
        KisiTamAd:kisiGuncelle.KisiAd + ' '+ kisiGuncelle.KisiSoyad,
        KisiAd:kisiGuncelle.KisiAd,
        KisiSoyad:kisiGuncelle.KisiSoyad,
        DogumTarihString:guncelleDogumTarihAcKapa ? tarihGuncelle.toISOString().substring(0,10) : "1900-01-01",
        DogumUlkeId:kisiGuncelle.DogumUlkeId,
        DogumSehirId:kisiGuncelle.DogumSehirId,
          KisiUyruklar:kisiGuncelle.KisiUyruklar
      };
      
      KisilerService.KisiBilgiGuncelle(yeniGuncelle)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          if(imageGuncelle != null){
            KisilerService.KisiResimGuncelle(imageGuncelle,kisiGuncelle.KisiId,basariliDosyaEklemeEvent);
          }
          setImageGuncelle(null);
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setKisiGuncelle(false);
          setKisiGuncelleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });
    }

    const kisiDurumDegistir = (aktif) => {
      let veri = {KisiId:kisiGuncelle.KisiId,Aktif:aktif,AktifKullaniciId:kullanici.KullaniciId};
      setIsLoading(true);
      KisilerService.KisiDurumGuncelle(veri)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setKisiGuncelle(false);
          setKisiGuncelleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });
    }

    const handleTarihEkleChange = (e) => {
      setTarihEkle(e);
      setKisiEkle({...kisiEkle, DogumTarihString : e.toISOString().substring(0,10)});
    }

    const handleTarihGuncelleChange = (e) => {
      setTarihGuncelle(e);
      setKisiGuncelle({...kisiGuncelle, DogumTarihString : e.toISOString().substring(0,10)});
    }

    const ekleUyrukEkleClick = () => {
        if(ekleUyruk.UlkeId === 0 || ekleUyruk.UlkeId === '0'){
            toast.warning("Ülke seçimi yapılmadı",{position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }else{
            let anasay = 0;
            let ulkesay =0 ;
            for(let i=0;i<kisiEkle.KisiUyruklar.length;i++){
                if(parseInt(kisiEkle.KisiUyruklar[i].UlkeId) === parseInt(ekleUyruk.UlkeId)){
                    ulkesay = ulkesay + 1;
                }
                if(kisiEkle.KisiUyruklar[i].AnaUyruk){
                    anasay = anasay + 1;
                }
            }

            let uyari = "";
            if(anasay > 1){
                uyari = uyari + "Ana uyruk daha önce seçilmişti !..";
            }
            if(ulkesay > 0){
                uyari = uyari + "Uyruk daha önce kişi için eklenmişti !..";
            }

            if(uyari !== ""){
                toast.warning(uyari,{position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            }else{
                setIsLoading(true);
                UlkelerService.UlkeIdyeGoreGetir(ekleUyruk.UlkeId)
                .then(response => {
                    setIsLoading(false);
                    let veri = {UlkeId:response.UlkeId,UlkeAdi:response.UlkeAdi,BayrakYolURL:response.BayrakYolURL,AnaUyruk:ekleUyruk.AnaUyruk}
                    
                    let uyruklar = [];
                    for(let i=0;i<kisiEkle.KisiUyruklar.length;i++){
                        uyruklar.push(kisiEkle.KisiUyruklar[i]);
                    }
                    uyruklar.push(veri);

                    setKisiEkle({...kisiEkle,KisiUyruklar:uyruklar});
                });
                setEkleUyruk({UlkeId:0,AnaUyruk:false});
            }
        } 
        
    }

    const guncelleUyrukEkleClick = () => {
      
      if(parseInt(ekleUyruk.UlkeId) === 0){
        toast.warning("Ülke seçimi yapılmadı",{position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
      }else{
          let anasay = 0;
          let ulkesay =0 ;
          for(let i=0;i<kisiGuncelle.KisiUyruklar.length;i++){
              if(parseInt(kisiGuncelle.KisiUyruklar[i].UlkeId) === parseInt(ekleUyruk.UlkeId)){
                  ulkesay = ulkesay + 1;
              }
              if(kisiGuncelle.KisiUyruklar[i].AnaUyruk){
                  anasay = anasay + 1;
              }
          }
          if(ekleUyruk.AnaUyruk){
            anasay = anasay + 1;
          }

          let uyari = "";
          if(anasay > 1){
              uyari = uyari + "Ana uyruk daha önce seçilmişti !..";
          }
          if(ulkesay > 0){
              uyari = uyari + "Uyruk daha önce kişi için eklenmişti !..";
          }

          if(uyari !== ""){
              toast.warning(uyari,{position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          }else{
              setIsLoading(true);
              UlkelerService.UlkeIdyeGoreGetir(ekleUyruk.UlkeId)
              .then(response => {
                  setIsLoading(false);
                  let veri = {UlkeId:response.UlkeId,UlkeAdi:response.UlkeAdi,UlkeBayrakYolURL:response.BayrakYolURL,AnaUyruk:ekleUyruk.AnaUyruk}
                  
                  let uyruklar = [];
                  for(let i=0;i<kisiGuncelle.KisiUyruklar.length;i++){
                      uyruklar.push(kisiGuncelle.KisiUyruklar[i]);
                  }
                  uyruklar.push(veri);

                  setKisiGuncelle({...kisiGuncelle,KisiUyruklar:uyruklar});
              });
              setEkleUyruk({UlkeId:0,AnaUyruk:false});
          }
      } 
    
    }

    const ekleUyrukCikart = (ulkeid) => {
        let uyruklar = [];
        for(let i=0;i<kisiEkle.KisiUyruklar.length;i++){
            if(kisiEkle.KisiUyruklar[i].UlkeId !== ulkeid){
                uyruklar.push(kisiEkle.KisiUyruklar[i]);
            }
        }
        setKisiEkle({...kisiEkle,KisiUyruklar:uyruklar});
    }

    const guncelleUyrukCikart = (ulkeid) => {
      let uyruklar = [];
      for(let i=0;i<kisiGuncelle.KisiUyruklar.length;i++){
          if(kisiGuncelle.KisiUyruklar[i].UlkeId !== ulkeid){
              uyruklar.push(kisiGuncelle.KisiUyruklar[i]);
          }
      }
      setKisiGuncelle({...kisiGuncelle,KisiUyruklar:uyruklar});
    }

    useEffect(() => {      
        setIsLoading(true);        
        UlkelerService.AramayaGoreGetir("TÜMÜ","","TÜMÜ")
        .then(response => {
          setIsLoading(false);
          setUlkeler(response);
          setSehirler([]);     
          handleAraClick();
        });        
    },[]);
    return (
    <>
      <ToastContainer/>
      <AdminLayoutPageHeader title="Kişiler" subtitle="" />
      <WrapRow isLoading={isLoading}>
        <Card12>
          <Kriter12>
              <Button renk="green" onClick={() => {setKisiEkleModal(true)}}>Kişi Ekle</Button>
          </Kriter12>
          <KriterWrap>
            <Kriter03 title="Ülke :">
              <select value={kriterler.UlkeId} disabled={isLoading} name="UlkeId"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={(e) => {ulkeChangeEvent(e.target.value)}}>
                <option value={0}>TÜMÜ</option>
                {ulkeler.map(item => (
                  <option key={item.UlkeId} value={item.UlkeId}>{item.UlkeAdi}</option>
                ))}
              </select>
            </Kriter03>
            <Kriter03 title="Durum :">
              <select value={kriterler.Durum} disabled={isLoading} name="Durum"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={araKriterChangeHandle}>
                <option value={2}>Tümü</option>
                <option value={1}>Aktif Kişiler</option>
                <option value={0}>Pasif Kişiler</option>
              </select>
            </Kriter03>          
            <Kriter03 title="Ara :">
              <input disabled={isLoading} name="Ara"
                     className={`border border-gray-700 p-2 rounded-md mb-2`} 
                     onChange={araKriterChangeHandle}                     
                     onKeyDown={araKeyDownEvent} 
                     value={kriterler.Ara}
                     type="text" 
                     autoComplete='off'
                     placeholder='Arama yapın'  />
            </Kriter03>
            <Kriter03 title={`Toplam Veri : `+ tabloVeriler.length}>
              <LoadingButton size={100} renk="indigo" onClick={handleAraClick} title="Ara" loading={isLoading}/>
            </Kriter03>
          </KriterWrap>
          <div className='flex flex-wrap justify-start items-start'>
          <PaginationDiv 
              isLoading={isLoading}
              bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.."
              sayfalama={true} sayfaSayisi={20}
              body={tabloVeriler.map(veri => (
                <div key={veri.KisiId} className='w-1/4'>
                  <div onClick={() => {kisiGuncelleAc(veri.KisiId)}} className='cursor-pointer m-1 border border-black hover:bg-gray-100 bg-white rounded-md flex flex-col justify-start items-start'>
                    <div className='w-full flex flex-row p-2 justify-start items-center'>
                        <img src={process.env.REACT_APP_API_URL + veri.UlkeBayrakYolURL}  className='w-[24px] h-6 object-contain bg-white' alt={veri.UlkeAdi} />
                        <span className='font-bold ml-3 text-blue-700'>{veri.KisiTamAd}</span>
                    </div>
                    <div className='flex flex-row justify-start items-center'>
                      <img src={process.env.REACT_APP_API_URL + veri.ResimYolURL}  className='w-[100px] h-24 m-1 object-contain bg-white rounded-lg p-1' alt={veri.KisiTamAd} />
                      <div className='flex flex-col justify-between items-start'>

                        <div className='flex flex-row justify-start items-center mt-1'>
                            <TbSun fill="black" size={24}/>
                            <span className='text-sm ml-2'>{veri.Yas !== 0 ? veri.Yas:'??'}</span>
                        </div>
                        <div className='flex flex-row justify-start items-center mt-1'>
                            <BiCalendar fill="black" size={24} />
                            <span className='text-sm ml-2'>{veri.Yas !== 0 ? veri.DogumTarihString2:'...........'}</span>
                        </div>
                        <div className='flex flex-row justify-start items-center mt-1'>
                            <MdLocationPin fill="black" size={24}/>
                            <span className='text-sm ml-2'>{veri.DogumSehir === "" ? "Yer bilgisi yok" : veri.DogumSehir + "/" + veri.DogumUlke}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            />  
          </div>
   
        </Card12>       
      </WrapRow>


      {kisiEkleModal && (
          <Modal
          Baslik="Yeni Kişi Ekle"
          KapatTusu={true}
          kapatFonksiyon={() => setKisiEkleModal(false)}
          bodyIsHtml={false}
          body={
              <div className={`w-[900px] flex flex-row justify-start`}>
                <div className='flex flex-col justify-start items-start w-1/4'>                    
                    <div className='w-full flex flex-col justify-start items-center cursor-pointer' onClick={handleImageClick}>
                    
                        {image ? (<img src={URL.createObjectURL(image)}  className='w-full border border-black p-1 rounded-lg' alt='Yeni Ürün Resim' />) :(<img src={noimage}  className='w-full h-30 object-contain border border-black p-1 rounded-lg' alt='Yeni Ürün Resim' />)}
                        <input type='file' ref={inputRef} style={{display:"none"}}
                            onChange={handleImageChange}/>
                        
                    </div>
                    <div className='flex flex-row justify-center items-center w-full'>
                    <span onClick={() => {setImage(null)}} className='cursor-pointer underline hover:font-bold'>Resim Sıfırla</span>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start'>
                        <span className='font-bold mt-2'>Uyruklar :</span>
                        {kisiEkle.KisiUyruklar.map((ulke,index) => (
                            <Fragment key={index}>
                                {ulke.AnaUyruk && (
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <img src={process.env.REACT_APP_API_URL + ulke.BayrakYolURL}  className='w-[40px] object-contain bg-white rounded-lg p-2' alt={ulke.UlkeAdi}/>
                                        <span className='font-bold'>{ulke.UlkeAdi}</span>
                                            <TbTrash className='mx-2 cursor-pointer' size={24} fill='red' onClick={() => ekleUyrukCikart(ulke.UlkeId)} />
                                    </div>
                                )}
                            </Fragment>
                        ))}
                        {kisiEkle.KisiUyruklar.map((ulke,index) => (
                            <Fragment key={index}>
                                {!ulke.AnaUyruk && (
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <img src={process.env.REACT_APP_API_URL + ulke.BayrakYolURL}  className='w-[30px] object-contain bg-white rounded-lg p-2' alt={ulke.UlkeAdi}/>
                                        <div className='flex flex-row justify-end items-center'>
                                            <span className='text-sm'>{ulke.UlkeAdi} {ulke.AnaUyruk ? "(*)":""}</span>
                                            <TbTrash className='mx-2 cursor-pointer' size={24} fill='red' onClick={() => ekleUyrukCikart(ulke.UlkeId)} />
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className='flex flex-col justify-start items-start w-3/4 ml-4'>
                    <div className='flex flex-row justify-between items-center w-full'>
                        <span className='w-1/4'>Ad :</span>
                        <input disabled={isLoading} name="KisiAd" value={kisiEkle.KisiAd} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                            onChange={kisiEkleChangeHandle}/>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Soyad :</span>
                        <input disabled={isLoading} name="KisiSoyad" value={kisiEkle.KisiSoyad} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                            onChange={kisiEkleChangeHandle}/>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Tam Ad :</span>
                        <input disabled={true} name="KisiTamAd" value={kisiEkle.KisiAd +' '+kisiEkle.KisiSoyad}
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                            onChange={kisiEkleChangeHandle}/>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Doğum Tarihi :</span>
                        <div className='w-3/4 flex flex-row justify-start items-center'>
                            <input type='checkbox' className='mr-2' onChange={(e) => {setEkleDogumTarihAcKapa(e.target.checked)}}></input>                   
                            {ekleDogumTarihAcKapa && (
                            <DatePicker name="DogumTarih" locale={tr} showYearDropdown={true} selected={tarihEkle} dateFormat="dd.MM.yyyy" 
                            className='border border-gray-700 p-2 rounded-md' onChange={handleTarihEkleChange}/>
                            )}
                        </div>
                    </div>
                    
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Doğum Ülke :</span>
                        <select name='DogumUlkeId' disabled={isLoading} value={kisiEkle.DogumUlkeId} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                            onChange={kisiEkleUlkeChangeHandle}>
                            <option value="0">Seçiniz</option>
                            {ulkeler.map((ulke) => (
                            <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Doğum Şehir :</span>
                        <select name='DogumSehirId' disabled={isLoading} value={kisiEkle.DogumSehirId} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                            onChange={kisiEkleChangeHandle}>
                            <option value="0">Seçiniz</option>
                            {sehirler.map((sehir,index) => (
                                <option key={index} value={sehir.SehirId}>{sehir.SehirAdi}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col justify-start items-start w-full mt-2'>
                        <span className='mb-2'>Uyruk Ekle :</span>
                        <div className='flex flex-row justify-between items-center w-full'>
                            <div className='w-1/2'>
                                <select name='UlkeId' disabled={isLoading} value={ekleUyruk.UlkeId} 
                                    className={`border border-gray-700 p-2 rounded-md`} 
                                    onChange={ekleUyrukChange}>
                                    <option value="0">Seçiniz</option>
                                    {ulkeler.map((ulke) => (
                                    <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='w-2/6'>
                                <input type='checkbox' name='AnaUyruk' checked={ekleUyruk.AnaUyruk} className='ml-2 mr-2' onChange={ekleUyrukChange}></input>
                                <span>Ana Uyruk</span>
                            </div>
                            <div className='w-1/6'>
                                <button onClick={ekleUyrukEkleClick} className='bg-purple-700 hover:bg-purple-900 text-white rounded-md px-4 py-2'>Uyruk Ekle</button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={kisiEkleClick} title="Ekle" loading={isLoading}/>
                  <Button renk="white" onClick={() => setKisiEkleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
      )}

      {kisiGuncelleModal && (
          <Modal
          Baslik="Kişi Güncelle"
          KapatTusu={true}
          kapatFonksiyon={() => setKisiGuncelleModal(false)}
          bodyIsHtml={false}
          body={
            <Fragment>
            <div className={`w-[900px] flex flex-row justify-start`}>
                <div className='flex flex-col justify-start items-start w-1/4'>                    
                    <div className='w-full flex flex-col justify-start items-center cursor-pointer' onClick={handleImageGuncelleClick}>
                                     
                      {imageGuncelle ? (<img src={URL.createObjectURL(imageGuncelle)}  className='w-full border border-black p-1 rounded-lg' alt='Ürün Resim Güncelle' />) :(<img src={process.env.REACT_APP_API_URL + kisiGuncelle.ResimYolURL}  className='w-full h-30 object-contain border border-black p-1 rounded-lg' alt='Ürün Resim Güncelle'/>)}
                      <input type='file' ref={inputGuncelleRef} style={{display:"none"}}
                        onChange={handleImageGuncelleChange}/>
                    </div>
                    <div className='flex flex-row justify-center items-center w-full'>
                      <span onClick={() => {setImageGuncelle(null)}} className='cursor-pointer underline hover:font-bold'>Resim Sıfırla</span>
                    </div>
                    <div className='w-full flex flex-col justify-start items-start'>
                        <span className='font-bold mt-2'>Uyruklar :</span>
                        {kisiGuncelle.KisiUyruklar.map((ulke,index) => (
                            <Fragment key={index}>
                                {ulke.AnaUyruk && (
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <img src={process.env.REACT_APP_API_URL + ulke.UlkeBayrakYolURL}  className='w-[40px] object-contain bg-white rounded-lg p-2' alt={ulke.UlkeAdi}/>
                                        <span className='font-bold'>{ulke.UlkeAdi}</span>
                                            <TbTrash className='mx-2 cursor-pointer' size={24} fill='red' onClick={() => guncelleUyrukCikart(ulke.UlkeId)} />
                                    </div>
                                )}
                            </Fragment>
                        ))}
                        {kisiGuncelle.KisiUyruklar.map((ulke,index) => (
                            <Fragment key={index}>
                                {!ulke.AnaUyruk && (
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <img src={process.env.REACT_APP_API_URL + ulke.UlkeBayrakYolURL}  className='w-[30px] object-contain bg-white rounded-lg p-2' alt={ulke.UlkeAdi}/>
                                        <div className='flex flex-row justify-end items-center'>
                                            <span className='text-sm'>{ulke.UlkeAdi} {ulke.AnaUyruk ? "(*)":""}</span>
                                            <TbTrash className='mx-2 cursor-pointer' size={24} fill='red' onClick={() => guncelleUyrukCikart(ulke.UlkeId)} />
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className='flex flex-col justify-start items-start w-3/4 ml-4'>
                    <div className='flex flex-row justify-between items-center w-full'>
                        <span className='w-1/4'>Ad :</span>
                        <input disabled={isLoading} name="KisiAd" value={kisiGuncelle.KisiAd} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                            onChange={kisiGuncelleChangeHandle}/>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Soyad :</span>
                        <input disabled={isLoading} name="KisiSoyad" value={kisiGuncelle.KisiSoyad} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                            onChange={kisiGuncelleChangeHandle}/>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Tam Ad :</span>
                        <input disabled={true} name="KisiTamAd" value={kisiGuncelle.KisiAd +' '+kisiGuncelle.KisiSoyad}
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                            onChange={kisiGuncelleChangeHandle}/>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Doğum Tarihi :</span>
                        <div className='w-3/4 flex flex-row justify-start items-center'>
                            <input type='checkbox' checked={guncelleDogumTarihAcKapa} className='mr-2' onChange={(e) => {setGuncelleDogumTarihAcKapa(e.target.checked)}}></input>                   
                            {guncelleDogumTarihAcKapa && (
                            <DatePicker name="DogumTarihString" locale={tr} showYearDropdown={true} selected={tarihGuncelle} dateFormat="dd.MM.yyyy" 
                            className='border border-gray-700 p-2 rounded-md' onChange={handleTarihGuncelleChange}/>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Doğum Ülke :</span>
                        <select name='DogumUlkeId' disabled={isLoading} value={kisiGuncelle.DogumUlkeId} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                            onChange={kisiGuncelleUlkeChangeHandle}>
                            <option value="0">Seçiniz</option>
                            {ulkeler.map((ulke) => (
                            <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full mt-2'>
                        <span className='w-1/4'>Doğum Şehir :</span>
                        <select name='DogumSehirId' disabled={isLoading} value={kisiGuncelle.DogumSehirId} 
                            className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                            onChange={kisiGuncelleChangeHandle}>
                            <option value="0">Seçiniz</option>
                            {sehirler.map((sehir,index) => (
                                <option key={index} value={sehir.SehirId}>{sehir.SehirAdi}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col justify-start items-start w-full mt-2'>
                        <span className='mb-2'>Uyruk Ekle :</span>
                        <div className='flex flex-row justify-between items-center w-full'>
                            <div className='w-1/2'>
                                <select name='UlkeId' disabled={isLoading} value={ekleUyruk.UlkeId} 
                                    className={`border border-gray-700 p-2 rounded-md`} 
                                    onChange={ekleUyrukChange}>
                                    <option value="0">Seçiniz</option>
                                    {ulkeler.map((ulke) => (
                                    <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='w-2/6'>
                                <input type='checkbox' name='AnaUyruk' checked={ekleUyruk.AnaUyruk} className='ml-2 mr-2' onChange={ekleUyrukChange}></input>
                                <span>Ana Uyruk</span>
                            </div>
                            <div className='w-1/6'>
                                <button onClick={guncelleUyrukEkleClick} className='bg-purple-700 hover:bg-purple-900 text-white rounded-md px-4 py-2'>Uyruk Ekle</button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </Fragment>

          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={kisiBilgiGuncelleClick} title="Güncelle" loading={isLoading}/>
                  {kisiGuncelle.Aktif ? (
                    <LoadingButton renk="red" onClick={() => {kisiDurumDegistir(false)}} title="Pasifleştir" loading={isLoading}/>
                  ) : (
                    <LoadingButton renk="teal" onClick={() => {kisiDurumDegistir(true)}} title="Aktifleştir" loading={isLoading}/>
                  )}
                  
                  
                  <Button renk="white" onClick={() => setKisiGuncelleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}

    </>
    )
}

export default Kisiler