import React from 'react'
import MoonLoader from "react-spinners/MoonLoader";

const Yukleniyor = () => {
  return (
    <div className="bg-zinc-400/30 fixed inset-0 z-50 max-h-screen 
        overflow-hidden overflow-y-scroll dark:bg-zinc-700/80">
        <div className="flex h-screen max-w-5xl m-auto justify-center items-center">
            <div className="bg-transparent rounded-lg relative dark:bg-gray-700 z-10">                
              <MoonLoader  color="#435ebe" size={150} />
            </div>
        </div>
    </div>
  )
}

export default Yukleniyor