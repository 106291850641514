import React, {useState,useEffect} from 'react';
import {GeriBildirimlerService } from '../services';
import bg2 from '../assets/images/bg2.jpg';
import Modal from '../components/Modal';
import Button from '../components/Button';
import {Helmet} from 'react-helmet';
import Spinner from '../assets/images/spinner.gif';
import { useTranslation } from 'react-i18next';
import {useStateContext} from '../contexts/ContextProvider';

const ComingSoon = () => {
    const [t,i18n] = useTranslation("global");
    const {setLanguage} = useStateContext();
    const [gun,setGun] = useState(0)
    const [saat,setSaat] = useState(0)
    const [dakika,setDakika] = useState(0)
    const [saniye,setSaniye] = useState(0)
    const [adres,setAdres] = useState('')
    const [modal,setModal] = useState(false)
    const [modalAciklama,setModalAciklama] = useState('')
    const [modalBaslik,setModalBaslik] = useState('')
    const [loading,setLoading] = useState(false);
    
    
    const bitisTarih = "10.01.2023";
    const zamanHesaplama = () => {
        const zaman = Date.parse(bitisTarih) - Date.now();
        if(zaman < 0){
            setGun(0);
            setSaat(0);
            setDakika(0);
            setSaniye(0);
        }else{
            setGun(Math.floor(zaman / (1000 * 60 * 60 * 24)));
            setSaat(Math.floor((zaman / (1000 * 60 * 60)) % 24));
            setDakika(Math.floor((zaman / 1000 / 60) % 60));
            setSaniye(Math.floor((zaman / 1000) % 60));
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            zamanHesaplama(bitisTarih)
        }, 1000);

        return () => clearInterval(interval);
    },[])

    const haberverClickHandle = ()=> {
        /*
        
        setLoading(true);
        GeriBildirimlerService.acilishaberver(adres)
        .then(response => {
            if(response.Sonuc){ document.getElementById('deneme').value = ''}
            setLoading(false);
            setModalBaslik(response.Sonuc ? t("genelyazilar.bilgi") : t("genelyazilar.uyari"))
            setModalAciklama(response.SonucAciklama)
            setModal(true);
        });
        */
    }
  return (
    <>
        <Helmet>
            <title>Stat Stand - {t("comingsoon.baslik_hazirlaniyoruz")}</title>
            <meta name="description" content={`Pardon bakar mısınız pardonbakarmisiniz cafe kahve çay yemek masa hesap sipariş`} />
        </Helmet>
        <div className="m-0 p-0 box-border font-kanit">
            <section className="flex gap-y-4 items-center justify-center flex-col h-screen w-full overflow-hidden">
                <img src={bg2}  alt="" className="h-full w-full object-cover opacity-40 absolute -z-10" />
                <div className="flex flex-col gap-y-4 items-center justify-center max-md:m-0">
                    <div>
                        <button className='py-1 px-3 m-1 border border-black rounded-md hover:bg-white' onClick={() => changeLanguage("tr")}>TR</button>
                        <button className='py-1 px-3 m-1 border border-black rounded-md hover:bg-white' onClick={() => changeLanguage("en")}>EN</button>
                        <button className='py-1 px-3 m-1 border border-black rounded-md hover:bg-white' onClick={() => changeLanguage("es")}>ES</button>
                        <button className='py-1 px-3 m-1 border border-black rounded-md hover:bg-white' onClick={() => changeLanguage("de")}>DE</button>
                    </div>
                    <header className="text-7xl text-black font-semibold text-center max-md:text-4xl">{t("comingsoon.baslik_hazirlaniyoruz")}</header>
                    <p className="text-lg font-normal text-black max-w-md text-center max-md:text-base pr-3 pl-3">
                    {t("comingsoon.aciklama")}
                    </p>

                    <div className="flex gap-x-8 items-center">
                        <div className="flex items-center flex-col">
                            <span className="text-black font-semibold text-6xl max-md:text-5xl">{(gun < 10 ? "0" + gun.toString():gun.toString())}</span>
                            <span className="text-black font-semibold capitalize text-base">{t("comingsoon.gun")}</span>
                        </div>
                        <div className="flex items-center flex-col">
                            <span className="text-black font-semibold text-6xl max-md:text-5xl">{(saat < 10 ? "0" + saat.toString():saat.toString())}</span>
                            <span className="text-black font-semibold capitalize text-base">{t("comingsoon.saat")}</span>
                        </div>
                        <div className="flex items-center flex-col">
                            <span className="text-black font-semibold text-6xl max-md:text-5xl">{(dakika < 10 ? "0" + dakika.toString():dakika.toString())}</span>
                            <span className="text-black font-semibold capitalize text-base">{t("comingsoon.dakika")}</span>
                        </div>
                        <div className="flex items-center flex-col">
                            <span className="text-black font-semibold text-6xl max-md:text-5xl">{(saniye < 10 ? "0" + saniye.toString():saniye.toString())}</span>
                            <span className="text-black font-semibold capitalize text-base">{t("comingsoon.saniye")}</span>
                        </div>
                    </div>
                    <div className="flex items-center flex-col mt-8 w-full pr-2 pl-2">
                        <p className="text-sm font-medium text-black">{t("comingsoon.haberver_aciklama")}</p>
                        <div className="flex items-center h-10 max-w-sm w-full mt-4 gap-x-3 ">
                            <input id='deneme' className="w-full px-4 text-black h-full outline-none border border-solid focus:bg-gray-100
                            border-gray-400 rounded bg-white/[0.2] text-sm hover:border-black placeholder:text-gray-500 placeholder:text-xs disabled:bg-black" 
                            type="email" autoComplete='off' placeholder={`${t("comingsoon.mailgirinuyari")}`} onChange={(e) => setAdres(e.target.value)}  disabled={loading}/>

                            <button className="h-full outline-none text-sm border border-solid border-gray-400
                            rounded bg-white[0.2] font-normal cursor-pointer text-black whitespace-nowrap px-5 transition-all 
                            duration-200 ease-in hover:text-[#0d6a81] hover:bg-white disabled:bg-black" onClick={haberverClickHandle} disabled={loading}>
                                {loading ? <img src={Spinner} className="h-[25px]" alt={`${t("comingsoon.haberverbutton")}`}/> : t("comingsoon.haberverbutton")}
                            </button>
                            {modal && (
                                <Modal 
                                Baslik={modalBaslik}
                                KapatTusu={true} 
                                kapatFonksiyon={() => setModal(false)} 
                                bodyIsHtml={true} 
                                body={modalAciklama}
                                footer={
                                <Button renk="red" onClick={() => setModal(false)}>{t("genelyazilar.cikis")}</Button>
                                }
                                />

                            )}
                            
                        </div>
                    </div>
                </div>
            </section>
        </div> 
    </>       
  )
}


export default ComingSoon