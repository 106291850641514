import {React,useState} from 'react'
import { ToastContainer} from 'react-toastify'
import WrapRow from '../../components/designs/WrapRow'
import Card12 from '../../components/designs/Card12'
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import KriterWrap from '../../components/designs/KriterWrap'
import Kriter02 from '../../components/designs/Kriter02'
import LoadingButton from '../../components/LoadingButton'
import { useEffect } from 'react';
import { SezonlarService } from '../../services';
import TabloUfakYazi from '../../components/TabloUfakYazi';

const Sezonlar = () => {
    
    const [isLoading,setIsLoading] = useState(false);
    const [araKategori,setAraKategori] = useState('TÜMÜ');
    const [kategoriler,setKategoriler] = useState([]);
    const [tabloVeriler,setTabloVeriler] = useState([]);

    const handleAraClick = () => {
      setIsLoading(true);
      SezonlarService.SezonlarListesiniGetir(araKategori)
      .then(response => {
        setIsLoading(false);
        setTabloVeriler(response);
      });
    }

    useEffect(() => {
      setIsLoading(true);
      SezonlarService.SezonKategorileriGetir()
      .then(responsek => {
        setKategoriler(responsek);
        
        SezonlarService.SezonlarListesiniGetir(araKategori)
        .then(response => {
          setIsLoading(false);
          setTabloVeriler(response);
        });
      });
    },[]);
    return (
    <>
      <ToastContainer/>
      <AdminLayoutPageHeader title="Sezonlar" subtitle="" />
      <WrapRow isLoading={isLoading}>
        <Card12>
          <KriterWrap>
            <Kriter02 title="Kategori :">
              <select value={araKategori} disabled={isLoading}
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={(e) => {setAraKategori(e.target.value)}}>
                <option value="TÜMÜ">TÜMÜ</option>
                {kategoriler.map((item,index) => (
                  <option key={index} value={item}>{item}</option>
                ))}
              </select>
            </Kriter02>        
            <Kriter02 title="&nbsp;">
              <LoadingButton size={100} renk="indigo" onClick={handleAraClick} title="Ara" loading={isLoading}/>
            </Kriter02>
          </KriterWrap>
          <TabloUfakYazi isLoading={isLoading} bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.." sayfalama={true} sayfaSayisi={20}
              head={[
                      {name : 'ID',sortable:true,sayi:true},
                      {name : 'Sezon',sortable:true},                            
                      {name : 'Kategori',sortable:true,sayi:true},                            
                      {name : 'Baş.Tarih',sortable:true,sayi:false},
                      {name : 'Son.Tarih',sortable:true,sayi:false},
                  ]} 
              body={tabloVeriler.map(veri => ([
                  veri.SezonId,
                  veri.Adi,
                  veri.Kategori,
                  veri.BasTarihString,
                  veri.SonTarihString
              ]))}
          />       
        </Card12>       
      </WrapRow>

    </>
    )
}

export default Sezonlar