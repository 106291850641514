import React from 'react'

const Button = (props) => {
    const redClass = 'py-2 px-4 bg-red-600 rounded-md text-white font-medium hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-600 disabled:bg-red-900';
    const greenClass = 'py-2 px-4 bg-green-600 rounded-md text-white font-medium hover:bg-green-800 dark:bg-green-800 dark:hover:bg-green-600 disabled:bg-green-900';
    const blueClass = 'py-2 px-4 bg-blue-600 rounded-md text-white font-medium hover:bg-blue-800 dark:bg-blue-800 dark:hover:bg-blue-600 disabled:bg-blue-900';
    const yellowClass = 'py-2 px-4 bg-yellow-600 rounded-md text-white font-medium hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-600 disabled:bg-yellow-900';
    const orangeClass = 'py-2 px-4 bg-orange-600 rounded-md text-white font-medium hover:bg-orange-800 dark:bg-orange-800 dark:hover:bg-orange-600 disabled:bg-orange-900';
    const purpleClass = 'py-2 px-4 bg-purple-600 rounded-md text-white font-medium hover:bg-purple-800 dark:bg-purple-800 dark:hover:bg-purple-600 disabled:bg-purple-900';
    const pinkClass = 'py-2 px-4 bg-pink-600 rounded-md text-white font-medium hover:bg-pink-800 dark:bg-pink-800 dark:hover:bg-pink-600 disabled:bg-pink-900';
    const indigoClass = 'py-2 px-4 bg-indigo-600 rounded-md text-white font-medium hover:bg-indigo-800 dark:bg-indigo-800 dark:hover:bg-indigo-600 disabled:bg-indigo-900';
    const skyClass = 'py-2 px-4 bg-sky-600 rounded-md text-white font-medium hover:bg-sky-800 dark:bg-sky-800 dark:hover:bg-sky-600 disabled:bg-sky-900';
    const cyanClass = 'py-2 px-4 bg-cyan-600 rounded-md text-white font-medium hover:bg-cyan-800 dark:bg-cyan-800 dark:hover:bg-cyan-600 disabled:bg-cyan-900';
    const tealClass = 'py-2 px-4 bg-teal-600 rounded-md text-white font-medium hover:bg-teal-800 dark:bg-teal-800 dark:hover:bg-teal-600 disabled:bg-teal-900';
    const limeClass = 'py-2 px-4 bg-lime-600 rounded-md text-white font-medium hover:bg-lime-800 dark:bg-lime-800 dark:hover:bg-lime-600 disabled:bg-lime-900';
    const slateClass = 'py-2 px-4 bg-slate-600 rounded-md text-white font-medium hover:bg-slate-800 dark:bg-slate-800 dark:hover:bg-slate-600 disabled:bg-slate-900';
    const zincClass = 'py-2 px-4 bg-zinc-600 rounded-md text-white font-medium hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-zinc-600 disabled:bg-zinc-900';
    const amberClass = 'py-2 px-4 bg-amber-600 rounded-md text-white font-medium hover:bg-amber-800 dark:bg-amber-800 dark:hover:bg-amber-600 disabled:bg-amber-900';
    const stoneClass = 'py-2 px-4 bg-stone-600 rounded-md text-white font-medium hover:bg-stone-800 dark:bg-stone-800 dark:hover:bg-stone-600 disabled:bg-stone-900';
    const whiteClass = 'py-2 px-4 bg-white border border-gray-600 rounded-md text-black font-medium hover:bg-gray-300 dark:bg-gray-300 dark:hover:bg-white disabled:bg-gray-400';
 
    return (
        <>
            {props.renk === "red" && (<button className={redClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "green" && (<button className={greenClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "blue" && (<button className={blueClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "yellow" && (<button className={yellowClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "orange" && (<button className={orangeClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "purple" && (<button className={purpleClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "pink" && (<button className={pinkClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "indigo" && (<button className={indigoClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "sky" && (<button className={skyClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "cyan" && (<button className={cyanClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "teal" && (<button className={tealClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "lime" && (<button className={limeClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "slate" && (<button className={slateClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "zinc" && (<button className={zincClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "amber" && (<button className={amberClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "stone" && (<button className={stoneClass} onClick={props.onClick}>{props.children}</button>)}
            {props.renk === "white" && (<button className={whiteClass} onClick={props.onClick}>{props.children}</button>)}
        </>
        
  )
}

export default Button