import React, { Fragment, useEffect, useState } from 'react'

const TabloPagination = ({pages,setCurrentPage}) => {
   
    const numOfPages = [];
    
    for(let i = 1; i<= pages ; i++) {
        numOfPages.push(i);
    }

    const [currentButton,setCurrentButton] = useState(1);

    useEffect(() => {
        setCurrentPage(currentButton);
    },[currentButton,setCurrentPage])

  return (
    <div className="flex flex-row justify-end items-center gap-1">
        <button onClick={() => setCurrentButton(1)} className={`font-bold py-1 px-2 text-main-bg bg-white border border-main-bg hover:bg-main-content ${currentButton === 1 ? "pointer-events-none":""}`}>&lt;&lt;</button>
        <button onClick={() => setCurrentButton((prev) => prev === 1 ? prev : prev-1)} className={`font-bold py-1 px-2 text-main-bg bg-white border border-main-bg hover:bg-main-content ${currentButton === 1 ? "pointer-events-none":""}`}>&lt;</button>
        {(currentButton > 3) && (numOfPages.length > 5) && (<button className={`font-bold py-1 px-2 text-main-bg bg-white cursor-default`} disabled>...</button>)}
        {
            numOfPages.map((page,index) => {

                if((numOfPages.length > 5) && (currentButton > 3) && (currentButton < numOfPages.length -2) && (page >= currentButton -2) && (page <=currentButton+2)){
                    return (
                        <button key={index} onClick={() => setCurrentButton(page)} className={`font-bold py-1 px-2 border border-main-bg ${currentButton === page ? "text-white bg-main-bg cursor-default" : "text-main-bg bg-white hover:bg-main-content"}`}>{page}</button>                       
                    )
                }else if ((numOfPages.length > 5)&& (currentButton <=3) && (page <=5) ){
                    return (
                        <button key={index} onClick={() => setCurrentButton(page)} className={`font-bold py-1 px-2 border border-main-bg ${currentButton === page ? "text-white bg-main-bg cursor-default" : "text-main-bg bg-white hover:bg-main-content"}`}>{page}</button> 
                    )
                }
                else if ((numOfPages.length > 5)&& (currentButton >= numOfPages.length -2) && (page >=numOfPages.length - 4) ){
                    return (
                        <button key={index} onClick={() => setCurrentButton(page)} className={`font-bold py-1 px-2 border border-main-bg ${currentButton === page ? "text-white bg-main-bg cursor-default" : "text-main-bg bg-white hover:bg-main-content"}`}>{page}</button>  
                    )
                }else if (numOfPages.length <=5){
                    return (
                        <button key={index} onClick={() => setCurrentButton(page)} className={`font-bold py-1 px-2 border border-main-bg ${currentButton === page ? "text-white bg-main-bg cursor-default" : "text-main-bg bg-white hover:bg-main-content"}`}>{page}</button>  
                    )
                }
                else{
                    return (
                        <Fragment key={index}></Fragment>
                    )
                }

            })
        }
        {(currentButton < numOfPages.length -2) && (numOfPages.length > 5) && (<button className={`font-bold py-1 px-2 text-main-bg bg-white cursor-default`} disabled>...</button>)}
        <button onClick={() => setCurrentButton((prev) => prev === numOfPages.length ? prev : prev + 1)} className={`font-bold py-1 px-2 text-main-bg bg-white border border-main-bg hover:bg-main-content ${currentButton === numOfPages.length ? "pointer-events-none":""}`}>&gt;</button>
        <button onClick={() => setCurrentButton(numOfPages.length)} className={`font-bold py-1 px-2 text-main-bg bg-white border border-main-bg hover:bg-main-content ${currentButton === numOfPages.length ? "pointer-events-none":""}`}>&gt;&gt;</button>
    </div>
  )
}

export default TabloPagination
