import React,{ useState } from "react"
import {TiArrowSortedDown,TiArrowSortedUp,TiArrowUnsorted} from 'react-icons/ti'
import TabloPagination from "./TabloPagination";

const Tablo = ({isLoading,head,body,sayfalama,sayfaSayisi,bosVeriUyarisi}) => {
    const [sorting,setSorting] = useState(false)
    const [currentPage,setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(sayfalama ? sayfaSayisi : 10000);
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
    
    const filteredData = body.sort((a,b) => {
        if(sorting?.orderBy === 'asc'){
            return sorting.sayi ? (a[sorting.key] < b[sorting.key] ? -1 : 1) : (a[sorting.key].toString().toLocaleLowerCase('TR') < b[sorting.key].toString().toLocaleLowerCase('TR') ? -1 : 1);
        }
        else if(sorting?.orderBy === 'desc'){
            return sorting.sayi ? (b[sorting.key] < a[sorting.key] ? -1 : 1) : (b[sorting.key].toString().toLocaleLowerCase('TR') < a[sorting.key].toString().toLocaleLowerCase('TR') ? -1 : 1);
        }else {return body}
    })
    const currentData = filteredData.slice(indexOfFirstItem,indexOfLastItem)

    const totalPagesNum = Math.ceil(filteredData.length / itemsPerPage);
    
    return (
        <>
            {currentData.length > 0 ? (
                <>
                <div className="w-full border rounded p4">
                    <table className="w-full">
                        <thead>
                            <tr>
                                {head.map((h,key) => (
                                    <th width={h?.width}                                     
                                        className={`${h.center ? "text-center" : (h.right ? "text-right" : "text-left")} bg-gray-50 text-sm font-semibold text-gray-500 p-3 border ${h.islem ? "w-[100px]" : ""}`}
                                        key={key}>
                                        <div className="inline-flex items-center gap-x-2">
                                            {h.name}
                                            {h.sortable && (
                                            <button onClick={() =>{
                                                    if(sorting?.key === key){
                                                        setSorting({
                                                            sayi : h.sayi,
                                                            key,
                                                            orderBy: sorting.orderBy === 'asc' ? 'desc' : (sorting.orderBy === 'desc' ? '' : 'asc')
                                                        })
                                                    }else{
                                                        setSorting({
                                                            sayi : h.sayi,
                                                            key,
                                                            orderBy: 'asc'
                                                        })
                                                    }
                                                }}>
                                                    {sorting?.key === key && (
                                                        sorting.orderBy === 'asc' ? <TiArrowSortedUp size={20} />
                                                                                : (sorting.orderBy === 'desc' ? <TiArrowSortedDown size={20} />
                                                                                                                : <TiArrowUnsorted size={20} />)
                                                    )}
                                                    {sorting?.key !== key && <TiArrowUnsorted size={20} />}
                                                </button>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {currentData.map((items,key) =>(
                            <tr key={key} className="group">
                                {items.map((item,key) => (
                                    <td key={key} 
                                        className={`px-3 py-1 border text-base font-semibold group-hover:bg-blue-50 group-hover:text-blue-600`}>
                                        
                                        {Array.isArray(item) ? (
                                            <div className={`flex  ${item.islem ? "flex-row justify-end" : "flex-col  gap-x-2.5 justify-center"}  text-sm`}>
                                                {item}
                                            </div>
                                        ) : item}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {sayfalama && (
                    <div className="flex flex-row justify-between items-center mt-2">
                        <div className="">
                            <select defaultValue={itemsPerPage} onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}} className="w-[70px] p-2 border border-gray-500 rounded-md">
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                
                            </select>
                            <span className="ml-3 text-sm">
                                {filteredData.length} veriden {indexOfFirstItem + 1} ile {filteredData.length < indexOfLastItem ? filteredData.length : indexOfLastItem} arası veriler gösteriliyor !..
                            </span>
                        </div>
                        <div>
                            <TabloPagination pages={totalPagesNum} setCurrentPage={setCurrentPage} />
                        </div>
                    </div>
                )}

                </>
            ) : (
                !isLoading && (                
                <div className="w-full">
                    <div className='p-4 rounded bg-yellow-100 text-yellow-700 text-sm mb-5'>
                        
                        {(bosVeriUyarisi==='' || bosVeriUyarisi === null) ? 'Aranan kriterlere göre veri bulunamadı !..' : bosVeriUyarisi}
                    </div>
                </div>)

            )}
            
            
            
        </>
    )
}
export default Tablo