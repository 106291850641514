import { BrowserRouter,Routes,Route } from "react-router-dom";
import Page404 from './pages/Page404';
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import AdminLayout from './layouts/AdminLayout';
import Anasayfa from './pages/yonetici/Anasayfa';
import { useStateContext } from "./contexts/ContextProvider";
import 'react-toastify/dist/ReactToastify.css';
import ComingSoon from "./pages/ComingSoon";
import YoneticiGiris from "./pages/yonetici/YoneticiGiris";
import Ulkeler from "./pages/yonetici/Ulkeler";
import Sehirler from "./pages/yonetici/Sehirler";
import Stadyumlar from './pages/yonetici/Stadyumlar';
import Takimlar from './pages/yonetici/Takimlar';
import Ligler from './pages/yonetici/Ligler';
import Kullanicilar from './pages/yonetici/Kullanicilar';
import Kisiler from "./pages/yonetici/Kisiler";
import KullaniciAyarlar from "./pages/yonetici/KullaniciAyarlar";
import Sezonlar from "./pages/yonetici/Sezonlar";

function App() {
  const {currentMode} = useStateContext();
  return (
    <div className={currentMode === 'Dark' ? 'dark' : 'light'}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<ComingSoon />} />
            <Route path="/yoneticigiris" element={<YoneticiGiris />} />
            <Route path="/yonetici" element={<AdminPrivateRoute><AdminLayout/></AdminPrivateRoute>}>
              <Route index={true} path="" element={<Anasayfa/>} />
              <Route path="anasayfa" element={<Anasayfa/>} />
              <Route path="kullaniciayarlar" element={<KullaniciAyarlar/>} />
              <Route path="ulkeler" element={<Ulkeler />}/>
              <Route path="sehirler" element={<Sehirler />}/>
              <Route path="stadyumlar" element={<Stadyumlar />}/>
              <Route path="takimlar" element={<Takimlar />}/>
              <Route path="ligler" element={<Ligler />}/>
              <Route path="kullanicilar" element={<Kullanicilar />}/>
              <Route path="kisiler" element={<Kisiler />}/>
              <Route path="sezonlar" element={<Sezonlar />}/>
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>  
    </div>
  );
}

export default App;
