import React from 'react';
import { useNavigate } from 'react-router-dom';
import bg404 from '../assets/images/bg404.jpg';

const Page404 = () => {
    const navigate = useNavigate()

    const anasayfayaDon = () => {
        navigate('/');
    }
  return (
    <div className="m-0 p-0 box-border font-kanit">
        <section className="flex gap-y-4 items-center justify-center flex-col h-screen w-full overflow-hidden">
            <img src={bg404}  alt="" className="h-full w-full object-cover absolute -z-10" />
            <div className="flex flex-col gap-y-4 items-center justify-center  max-md:m-0">
                <header className="text-7xl text-black font-semibold text-center max-md:text-4xl">
                    Sayfa <br/>Bulunamadı !..
                </header>
                <p className="text-lg font-normal text-black max-w-md text-center max-md:text-base 
                pr-3 pl-3">
                    Girmek istediğiniz sayfayı bulamadık. Anasayfaya geri dönerek işlemlerinize 
                    devam edebilirsiniz !..
                </p>

                <div className="flex items-center flex-col mt-8 w-full pr-2 pl-2">
                    <div className="flex justify-center items-center h-10 max-w-sm w-full mt-4 gap-x-3 ">                        
                        {/**/}

                        <button onClick={anasayfayaDon} 
                            className="h-full outline-none text-sm border border-solid
                         border-white rounded bg-orange-700 font-normal cursor-pointer
                          text-white whitespace-nowrap px-5 transition-all duration-200 
                          ease-in hover:text-orange-700 hover:bg-white">
                            Anasayfaya Geri Dön
                        </button>
                        
                    </div>
                </div>
            </div>
        </section>
    </div>   
  )
}

export default Page404