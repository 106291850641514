import { createContext,useContext, useEffect, useState } from "react";

const Context = createContext()
export const AdminAuthProvider = ({children}) => {
    const [kullanici,setKullanici] = useState(localStorage.getItem('kullanici') ? JSON.parse(localStorage.getItem('kullanici')) : false)
    const data = {kullanici,setKullanici}

    useEffect(() =>{
        localStorage.setItem('kullanici',JSON.stringify(kullanici))
    },[kullanici])

    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export const useKullaniciAuth =()=> useContext(Context)