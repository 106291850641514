import {React,useState,Fragment} from 'react'
import { ToastContainer,toast} from 'react-toastify'
import WrapRow from '../../components/designs/WrapRow'
import Card12 from '../../components/designs/Card12'
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import KriterWrap from '../../components/designs/KriterWrap'
import Kriter02 from '../../components/designs/Kriter02'
import Kriter12 from '../../components/designs/Kriter12'
import LoadingButton from '../../components/LoadingButton'
import Button from '../../components/Button'
import { useEffect } from 'react';
import {  StadyumlarService, SehirlerService, UlkelerService } from '../../services';
import TabloUfakYazi from '../../components/TabloUfakYazi';
import { useKullaniciAuth } from '../../contexts/AdminAuthProvider';
import Modal from '../../components/Modal';


const Stadyumlar = () => {
    const {kullanici} = useKullaniciAuth();
    const [isLoading,setIsLoading] = useState(false);
    const [kriterler,setKriterler] = useState({UlkeId:0,SehirId:0,Durum:2,Ara:''});
    const [ulkeler,setUlkeler] = useState([]);
    const [sehirler,setSehirler] = useState([]);
    const [tabloVeriler,setTabloVeriler] = useState([]);
    const [stadyumEkleModal,setStadyumEkleModal] = useState(false);
    const [stadyumEkle,setStadyumEkle] = useState({StadyumAdi:'',UlkeId:0,SehirId:0,Kapasite:0,KayitKullaniciId:kullanici.KullaniciId});
    const [sehirVeriler,setSehirVeriler] = useState([]);

    const [stadyumGuncelle,setStadyumGuncelle] = useState(false);
    const [stadyumGuncelleModal,setStadyumGuncelleModal] = useState(false);

    const handleAraClick = () => {
      setIsLoading(true);
      StadyumlarService.StadyumlarListesiniGetir(kriterler.UlkeId,kriterler.SehirId,kriterler.Durum,kriterler.Ara)
      .then(response => {           
          setIsLoading(false);
          setTabloVeriler(response);
      });
    }

    const araKeyDownEvent = (e) => {
      if(e.key === "Enter"){        
        handleAraClick();        
      }
    }

    const araKriterChangeHandle = (e) => {
        setKriterler({...kriterler,[e.target.name]:e.target.value});
    }

    const ulkeChangeEvent = (ulkeid) => {
      if(ulkeid !== 0 && ulkeid !== '0'){
        setKriterler({...kriterler,UlkeId:ulkeid,SehirId:0});
        SehirlerService.SehirlerListesiniGetir(ulkeid,1,"")
        .then(response => {
          setSehirler(response);
        });
      }else{
        setKriterler({...kriterler,UlkeId:0,SehirId:0});
        setSehirler([]);
      }
    }

    const stadyumEkleChangeHandle = (e) => {
      setStadyumEkle({...stadyumEkle,[e.target.name]:e.target.value});
    }

    const stadyumEkleUlkeChangeHandle = (e) => {
      let ulkeid = e.target.value;
      setStadyumEkle({...stadyumEkle,UlkeId:ulkeid});

      if(ulkeid === 0 || ulkeid === '0'){
        setSehirVeriler([]);
      }else{
        SehirlerService.SehirlerListesiniGetir(ulkeid,2,"")
        .then(response => {
          setSehirVeriler(response);
        });
      }
    }

    const stadyumEkleClick = () => {
      setIsLoading(true);
      StadyumlarService.StadyumEkle(stadyumEkle)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setStadyumEkle({StadyumAdi:'',UlkeId:0,SehirId:0,Kapasite:0,KayitKullaniciId:kullanici.KullaniciId});
          setStadyumEkleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });

    }

    const stadyumGuncelleAc = (stadyumid) => {
      setIsLoading(true);
      StadyumlarService.StadyumIdyeGoreGetir(stadyumid)
      .then(response => {
        setIsLoading(false);
        setStadyumGuncelle(response);
        setStadyumGuncelleModal(true);
        SehirlerService.SehirlerListesiniGetir(response.UlkeId,2,"")
        .then(response => {
          setSehirVeriler(response);
        });
      });
    }

    const stadyumGuncelleChangeHandle = (e) => {
      setStadyumGuncelle({...stadyumGuncelle,[e.target.name]:e.target.value});
    }

    const stadyumGuncelleUlkeChangeHandle = (e) => {
      let ulkeid = e.target.value;
      setStadyumGuncelle({...stadyumGuncelle,UlkeId:ulkeid});

      if(ulkeid === 0 || ulkeid === '0'){
        setSehirVeriler([]);
      }else{
        SehirlerService.SehirlerListesiniGetir(ulkeid,1,"")
        .then(response => {
          setSehirVeriler(response);
        });
      }
    }

    const stadyumBilgiGuncelleClick = () => {
      setIsLoading(true);
      StadyumlarService.StadyumBilgiGuncelle(stadyumGuncelle)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setStadyumGuncelle(false);
          setStadyumGuncelleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });
    }

    const stadyumDurumDegistir = (aktif) => {
      let veri = {StadyumId:stadyumGuncelle.StadyumId,Aktif:aktif,AktifKullaniciId:kullanici.KullaniciId};
      setIsLoading(true);
      StadyumlarService.StadyumDurumGuncelle(veri)
      .then(response => {
        setIsLoading(false);
        if(response.Sonuc){
          toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
          setStadyumGuncelle(false);
          setStadyumGuncelleModal(false);
          handleAraClick();
        }else{
          toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        }
      });
    }

    useEffect(() => {      
        setIsLoading(true);
        UlkelerService.AramayaGoreGetir("TÜMÜ","","TÜMÜ")
        .then(response => {
          setIsLoading(false);
          setUlkeler(response);
          setSehirler([]);
          handleAraClick();
        });        
    },[]);
    return (
    <>
      <ToastContainer/>
      <AdminLayoutPageHeader title="Stadyumlar" subtitle="" />
      <WrapRow isLoading={isLoading}>
        <Card12>
          <Kriter12>
              <Button renk="green" onClick={() => {setStadyumEkleModal(true)}}>Stadyum Ekle</Button>
          </Kriter12>
          <KriterWrap>
            <Kriter02 title="Ülke :">
              <select value={kriterler.UlkeId} disabled={isLoading} name="UlkeId"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={(e) => {ulkeChangeEvent(e.target.value)}}>
                <option value={0}>TÜMÜ</option>
                {ulkeler.map(item => (
                  <option key={item.UlkeId} value={item.UlkeId}>{item.UlkeAdi}</option>
                ))}
              </select>
            </Kriter02>
            <Kriter02 title="Şehirler :">
              <select value={kriterler.SehirId} disabled={isLoading} name="SehirId"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={araKriterChangeHandle}>
                <option value={0}>Tümü</option>
                {sehirler.map(item => (
                  <option key={item.SehirId} value={item.SehirId}>{item.SehirAdi}</option>
                ))}
              </select>
            </Kriter02>
            <Kriter02 title="Durum :">
              <select value={kriterler.Durum} disabled={isLoading} name="Durum"
                      className={`border border-gray-700 p-2 rounded-md mb-2`}                          
                      onChange={araKriterChangeHandle}>
                <option value={2}>Tümü</option>
                <option value={1}>Aktif Stadyumlar</option>
                <option value={0}>Pasif Stadyumlar</option>
              </select>
            </Kriter02>          
            <Kriter02 title="Ara :">
              <input disabled={isLoading} name="Ara"
                     className={`border border-gray-700 p-2 rounded-md mb-2`} 
                     onChange={araKriterChangeHandle}                     
                     onKeyDown={araKeyDownEvent} 
                     value={kriterler.Ara}
                     type="text" 
                     autoComplete='off'
                     placeholder='Arama yapın'  />
            </Kriter02>
            <Kriter02 title="&nbsp;">
              <LoadingButton size={100} renk="indigo" onClick={handleAraClick} title="Ara" loading={isLoading}/>
            </Kriter02>
          </KriterWrap>
          <TabloUfakYazi isLoading={isLoading} bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.." sayfalama={true} sayfaSayisi={20}
              head={[
                      {name : 'ID',sortable:true,sayi:true},
                      {name : 'Stadyum Adi',sortable:true},                            
                      {name : 'Kapasite',sortable:true,sayi:true},                            
                      {name : 'Ülke',sortable:true,sayi:false},
                      {name : 'Şehir',sortable:true,sayi:false},
                      {name : 'İşlemler',sortable:false,islem:true},
                  ]} 
              body={tabloVeriler.map(veri => ([
                  veri.StadyumId,
                  veri.StadyumAdi,
                  veri.Kapasite,
                  veri.UlkeAdi,
                  veri.SehirAdi,                 
                  [
                      <div key={veri.StadyumId} className="flex flex-row justify-end">
                          <div className=''>
                              <Button renk="orange" onClick={() =>{stadyumGuncelleAc(veri.StadyumId)}}>Güncelle</Button>
                          </div>
                      </div>
                  ]
              ]))}
          />       
        </Card12>       
      </WrapRow>


      {stadyumEkleModal && (
          <Modal
          Baslik="Yeni Stadyum Ekle"
          KapatTusu={true}
          kapatFonksiyon={() => setStadyumEkleModal(false)}
          bodyIsHtml={false}
          body={
              <div className='w-[600px] flex flex-col justify-start'>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Stadyum Adı :</span>
                      <input disabled={isLoading} name="StadyumAdi" value={stadyumEkle.StadyumAdi} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={stadyumEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Kapasite :</span>
                      <input disabled={isLoading} name="Kapasite" value={stadyumEkle.Kapasite} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="number" autoComplete='off' 
                        onChange={stadyumEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Ülke :</span>
                      <select name='UlkeId' disabled={isLoading} value={stadyumEkle.UlkeId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={stadyumEkleUlkeChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Şehir :</span>
                      <select name='SehirId' disabled={isLoading} value={stadyumEkle.SehirId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={stadyumEkleChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {sehirVeriler.map((sehir) => (
                          <option key={sehir.SehirId} value={sehir.SehirId}>{sehir.SehirAdi} {sehir.Aktif ? "" : " (Pasif Halde)"}</option>
                        ))}
                    </select>
                  </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={stadyumEkleClick} title="Ekle" loading={isLoading}/>
                  <Button renk="white" onClick={() => setStadyumEkleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}


      {stadyumGuncelleModal && (
          <Modal
          Baslik="Stadyum Güncelle"
          KapatTusu={true}
          kapatFonksiyon={() => setStadyumGuncelleModal(false)}
          bodyIsHtml={false}
          body={
              <div className='w-[600px] flex flex-col justify-start'>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Stadyum Adı :</span>
                      <input disabled={isLoading} name="StadyumAdi" value={stadyumGuncelle.StadyumAdi} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={stadyumGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Kapasite :</span>
                      <input disabled={isLoading} name="Kapasite" value={stadyumGuncelle.Kapasite} 
                        className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} type="number" autoComplete='off' 
                        onChange={stadyumGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Ülke :</span>
                      <select name='UlkeId' disabled={isLoading} value={stadyumGuncelle.UlkeId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={stadyumGuncelleUlkeChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='w-1/4'>Şehir :</span>
                      <select name='SehirId' disabled={isLoading} value={stadyumGuncelle.SehirId} 
                          className={`border border-gray-700 p-2 rounded-md mr-3 w-3/4`} 
                          onChange={stadyumGuncelleChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {sehirVeriler.map((sehir) => (
                          <option key={sehir.SehirId} value={sehir.SehirId}>{sehir.SehirAdi} {sehir.Aktif ? "" : " (Pasif Halde)"}</option>
                        ))}
                    </select>
                  </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={stadyumBilgiGuncelleClick} title="Güncelle" loading={isLoading}/>
                  {stadyumGuncelle.Aktif ? (
                    <LoadingButton renk="red" onClick={() => {stadyumDurumDegistir(false)}} title="Pasifleştir" loading={isLoading}/>
                  ) : (
                    <LoadingButton renk="teal" onClick={() => {stadyumDurumDegistir(true)}} title="Aktifleştir" loading={isLoading}/>
                  )}
                  
                  
                  <Button renk="white" onClick={() => setStadyumGuncelleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}

    </>
    )
}

export default Stadyumlar