import { get, postJSON } from './request';

export const KisilerListesiniGetir = (UlkeId,Durum,Ara) => get(`Kisiler/KisilerListesiniGetir?UlkeId=${UlkeId}&Durum=${Durum}&Ara=${Ara}`)
export const KisiIdyeGoreGetir = (KisiId) => get(`Kisiler/KisiIdyeGoreGetir?KisiId=${KisiId}`)
export const KisiEkle = (veri) => postJSON(`Kisiler/KisiEkle`,veri)
export const KisiBilgiGuncelle = (veri) => postJSON(`Kisiler/KisiBilgiGuncelle`,veri)
export const KisiDurumGuncelle = (veri) => postJSON(`Kisiler/KisiDurumGuncelle`,veri)

export const KisiResimGuncelle = (dosya,KisiId,basariliDosyaEklemeEvent) => {
    var formData = new FormData();
    formData.append('file',dosya);
    fetch(process.env.REACT_APP_API_URL + 'Kisiler/KisiResimGuncelle?KisiId='+KisiId, {
      method: 'POST', body: formData,
    })
      .then((res) => res.json())
      .then((data) => { basariliDosyaEklemeEvent()})
      .catch((err) => console.error(err));
}