import React,{ useState,useEffect } from 'react'
import { ToastContainer,toast } from 'react-toastify'
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import WrapRow from '../../components/designs/WrapRow';
import Card06 from '../../components/designs/Card06';
import Card12 from '../../components/designs/Card12';
import Tablo from '../../components/Tablo';
import { useStateContext } from '../../contexts/ContextProvider';
import { useKullaniciAuth } from '../../contexts/AdminAuthProvider';
import {KullanicilarService} from '../../services';
import Button from '../../components/Button';

const KullaniciAyarlar = () => {
  const [isLoading,setIsLoading] = useState(false);
  const {screenSize} = useStateContext();
  const {kullanici} = useKullaniciAuth();
  const [islemLoglar,setIslemLoglar] = useState([]);
  const [sifre,setSifre] = useState({KullaniciId:kullanici.KullaniciId,EskiSifre:'',YeniSifre:'',YeniSifreTekrar:''});

  const sifreChangeHandle = (e) => {
    setSifre({...sifre, [e.target.name]:e.target.value});
  }

  const sifreDegistirClick = () => {
    setIsLoading(true);
    
    KullanicilarService.KullaniciSifreDegistir(sifre)
    .then(response => {
      setIsLoading(false);
      if(response.Sonuc) 
      {
        setSifre({KullaniciId:kullanici.KullaniciId,EskiSifre:'',YeniSifre:'',YeniSifreTekrar:''});
        toast.info(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
      }else {
        toast.warn(response.SonucAciklama, {position: "top-right",autoClose: 2000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
      }      
    });
  }

  useEffect(()=> {
    /**/
    setIsLoading(true);
    KullanicilarService.KullaniciIslemLoglariniGetir(kullanici.KullaniciId)
    .then(response => {
      setIsLoading(false);
      setIslemLoglar(response);
    })
    
  },[]);
  return (
    <>
    <ToastContainer/>
    <AdminLayoutPageHeader title="Kullanıcı Ayarları" subtitle="Aşağıdaki size ait olan verilerinizi düzenleyebilirsiniz" />
    <WrapRow isLoading={isLoading}>
      <Card06 title="Kullanıcı Bilgileri" noborder={false}>
          <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>Ad Soyad :</span>
            <input type="text" name="AdSoyad" readOnly={true} autoComplete='off' defaultValue={kullanici.AdSoyad}className={`${screenSize >= 600 ? "w-3/4" : "w-full"} border border-gray-400 p-2 rounded-lg`}/>
          </div>
          <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>Mail Adresi :</span>
            <input type="text" name="MailAdres" readOnly={true} autoComplete='off' defaultValue={kullanici.MailAdres} className={`${screenSize >= 600 ? "w-3/4" : "w-full"} border border-gray-400 p-2 rounded-lg`}/>
          </div>
          <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>Kullanıcı Adı :</span>
            <input type="text" name="KullaniciAdi" readOnly={true} autoComplete='off' defaultValue={kullanici.KullaniciAdi}  className={`${screenSize >= 600 ? "w-3/4" : "w-full"} border border-gray-400 p-2 rounded-lg`}/>
          </div>
        </Card06>
        <Card06 title="Şifre Değiştir" noborder={false}>
        <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>Eski Şifre :</span>
            <input type="password" name="EskiSifre" autoComplete='off' value={sifre.EskiSifre} onChange={sifreChangeHandle} className={`${screenSize >= 600 ? "w-3/4" : "w-full"} border border-gray-400 p-2 rounded-lg`}/>
          </div>
          <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>Yeni Şifre :</span>
            <input type="password" name="YeniSifre" autoComplete='off' value={sifre.YeniSifre} onChange={sifreChangeHandle} className={`${screenSize >= 600 ? "w-3/4" : "w-full"} border border-gray-400 p-2 rounded-lg`}/>
          </div>
          <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>Yeni Şifre(Tekrar) :</span>
            <input type="password" name="YeniSifreTekrar" autoComplete='off' value={sifre.YeniSifreTekrar} onChange={sifreChangeHandle} className={`${screenSize >= 600 ? "w-3/4" : "w-full"} border border-gray-400 p-2 rounded-lg`}/>
          </div>
          <div className={`flex ${screenSize >= 600 ? "flex-row justify-between items-center" : "flex-col justify-start items-start"}  py-2 `}>
            <span>&nbsp;</span>
            <div className='w-3/4'>
              <Button renk="green" onClick={sifreDegistirClick} >Değiştir</Button>                
            </div>              
          </div>
        </Card06>
        <Card12 title="Kullanıcı İşlem Logları" noborder={false}>
          <Tablo isLoading={isLoading} sayfalama={false} bosVeriUyarisi='Kullanıcı işlem bilgisi bulunamadı !..'
                  head={[
                          {name : 'Tarih',sortable:true},
                          {name : 'İşlem',sortable:false}
                      ]} 
                  body={islemLoglar.map((log,index) => ([
                    log.IslemTipTarihString,
                    log.IslemTipAdi
                      
                  ]))}
              /> 
        </Card12>
    </WrapRow>

  </>
  )
}

export default KullaniciAyarlar