import React, { useEffect, useState } from 'react';
import { KullanicilarService } from '../../services';
import bg1 from '../../assets/images/bg2.jpg';
import { useKullaniciAuth} from '../../contexts/AdminAuthProvider';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spinner from '../../assets/images/spinner.gif';
import { ToastContainer, toast } from 'react-toastify';

const YoneticiGiris = () => {
    const [girisVeri,setGirisVeri] = useState({KullaniciAdi:'',Sifre:''});
    const navigate = useNavigate();    
    const {kullanici,setKullanici} = useKullaniciAuth();       
    const [isLoading,setIsLoading] = useState(false);    

    const veriChangeEvent = (e) => {
        setGirisVeri({...girisVeri,[e.target.name]:e.target.value});
    }
    const girisYapHandleClick = () => {
        
        setIsLoading(true);
        KullanicilarService.KullaniciGiris(girisVeri)
        .then(response => {
            setIsLoading(false);
            if(response.GirisSonuc.Sonuc){
                setKullanici(response.Kullanici);
                let veri = {KullaniciId:response.Kullanici.KullaniciId};
                KullanicilarService.KullaniciYoneticiMi(veri)
                .then(responseq => {
                    if(responseq.Sonuc){
                        navigate('/yonetici/anasayfa');
                    }else{
                        navigate('/kullanici/anasayfa');
                    }
                });
            }else{                
                toast.error(response.GirisSonuc.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
            }  
        });
        
    }

    
    useEffect(()=> {
        if(kullanici) {
            KullanicilarService.KullaniciYoneticiMi(kullanici)
            .then(response => {
                if(response.Sonuc){
                    return navigate('/yonetici/anasayfa');
                }
            });            
        }
    },[kullanici,navigate])
  return (
    <>
    <ToastContainer />
    <Helmet>
        <title>StatStand - Yönetici Girişi</title>
        <meta name="description" content={`Pardon bakar mısınız pardonbakarmisiniz cafe kahve çay yemek masa hesap sipariş`} />
    </Helmet>
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full font-kanit">
        <div className='hidden sm:block'>
            <img className='w-full h-full object-cover' src={bg1} alt="Resim" />
        </div>
        
        <div className='bg-stone-700 flex flex-col justify-center'>
            <div className='max-w-[500px] w-full mx-auto bg-stone-900 p-8 px-8 rounded-lg'>
                <h2 className='text-4xl text-white font-bold text-center mb-5'>Giriş Yapınız</h2>
                <div className='flex flex-col text-stone-400 py-2'>
                    <label>Kullanıcı Adı</label>
                    <input disabled={isLoading} 
                        className='rounded-lg bg-stone-800 mt-2 p-2 placeholder:text-gray-500 focus:border-red-500 focus:bg-stone-600 focus:outline-none disabled:bg-stone-600'
                        type="text" 
                        name='KullaniciAdi'
                        value={girisVeri.KullaniciAdi} autoComplete='off'
                        placeholder="Kullanıcı adı yada mail adresinizi giriniz" 
                        onChange={veriChangeEvent} />
                </div>
                <div className='flex flex-col text-stone-400 py-2'>
                    <label>Şifre</label>
                    <input disabled={isLoading} 
                        className='rounded-lg bg-stone-800 mt-2 p-2 placeholder:text-gray-500 focus:border-red-500 focus:bg-stone-600 focus:outline-none disabled:bg-stone-600' 
                        type="password" 
                        name='Sifre'
                        value={girisVeri.Sifre} autoComplete='off'
                        placeholder="Şifrenizi giriniz" onChange={veriChangeEvent} 
                        onKeyDown={(e)=> {
                            if(e.key==="Enter"){return girisYapHandleClick();}
                        }}  />
                </div>
                <button className='w-full flex justify-center items-center my-5 py-2 bg-orange-700 text-white font-semibold rounded hover:bg-orange-800 disabled:bg-orange-900'
                    type='button' onClick={girisYapHandleClick} disabled={isLoading}>
                    {isLoading ? <img src={Spinner} className="h-[25px]" alt="Giriş Yap" /> : "Giriş Yap"}        
                </button>
                
            </div>
        </div>
    </div>
    </>
    
  )
}

export default YoneticiGiris