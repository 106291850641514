import React from 'react'
import { useKullaniciAuth } from '../contexts/AdminAuthProvider'
import { Navigate } from 'react-router-dom'

const AdminPrivateRoute = ({children}) => {
    const {kullanici} = useKullaniciAuth();
    if(!kullanici){
        return <Navigate to="/yoneticigiris" />
    }
    return children
    
}

export default AdminPrivateRoute