import React from 'react';
import Spinner from '../assets/images/spinner.gif';

const LoadingButton = (props) => {
    const redClass = 'py-2 px-4 bg-red-600 rounded-md text-white font-medium hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-600 disabled:bg-red-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const greenClass = 'py-2 px-4 bg-green-600 rounded-md text-white font-medium hover:bg-green-800 dark:bg-green-800 dark:hover:bg-green-600 disabled:bg-green-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const blueClass = 'py-2 px-4 bg-blue-600 rounded-md text-white font-medium hover:bg-blue-800 dark:bg-blue-800 dark:hover:bg-blue-600 disabled:bg-blue-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const yellowClass = 'py-2 px-4 bg-yellow-600 rounded-md text-white font-medium hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-600 disabled:bg-yellow-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const orangeClass = 'py-2 px-4 bg-orange-600 rounded-md text-white font-medium hover:bg-orange-800 dark:bg-orange-800 dark:hover:bg-orange-600 disabled:bg-orange-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const purpleClass = 'py-2 px-4 bg-purple-600 rounded-md text-white font-medium hover:bg-purple-800 dark:bg-purple-800 dark:hover:bg-purple-600 disabled:bg-purple-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const pinkClass = 'py-2 px-4 bg-pink-600 rounded-md text-white font-medium hover:bg-pink-800 dark:bg-pink-800 dark:hover:bg-pink-600 disabled:bg-pink-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const indigoClass = 'py-2 px-4 bg-indigo-600 rounded-md text-white font-medium hover:bg-indigo-800 dark:bg-indigo-800 dark:hover:bg-indigo-600 disabled:bg-indigo-900' + (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const skyClass = 'py-2 px-4 bg-sky-600 rounded-md text-white font-medium hover:bg-sky-800 dark:bg-sky-800 dark:hover:bg-sky-600 disabled:bg-sky-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const cyanClass = 'py-2 px-4 bg-cyan-600 rounded-md text-white font-medium hover:bg-cyan-800 dark:bg-cyan-800 dark:hover:bg-cyan-600 disabled:bg-cyan-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const tealClass = 'py-2 px-4 bg-teal-600 rounded-md text-white font-medium hover:bg-teal-800 dark:bg-teal-800 dark:hover:bg-teal-600 disabled:bg-teal-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const limeClass = 'py-2 px-4 bg-lime-600 rounded-md text-white font-medium hover:bg-lime-800 dark:bg-lime-800 dark:hover:bg-lime-600 disabled:bg-lime-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const slateClass = 'py-2 px-4 bg-slate-600 rounded-md text-white font-medium hover:bg-slate-800 dark:bg-slate-800 dark:hover:bg-slate-600 disabled:bg-slate-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const zincClass = 'py-2 px-4 bg-zinc-600 rounded-md text-white font-medium hover:bg-zinc-800 dark:bg-zinc-800 dark:hover:bg-zinc-600 disabled:bg-zinc-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const amberClass = 'py-2 px-4 bg-amber-600 rounded-md text-white font-medium hover:bg-amber-800 dark:bg-amber-800 dark:hover:bg-amber-600 disabled:bg-amber-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
    const stoneClass = 'py-2 px-4 bg-stone-600 rounded-md text-white font-medium hover:bg-stone-800 dark:bg-stone-800 dark:hover:bg-stone-600 disabled:bg-stone-900'+ (props.size > 0 ? ' w-['+props.size+'px]' : '');
 
    return (
        <>
                        
            {props.renk === "red" && (<button className={redClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title} /> : props.title}</button>)}
            {props.renk === "green" && (<button className={greenClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "blue" && (<button className={blueClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "yellow" && (<button className={yellowClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "orange" && (<button className={orangeClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "purple" && (<button className={purpleClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "pink" && (<button className={pinkClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "indigo" && (<button className={indigoClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "sky" && (<button className={skyClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "cyan" && (<button className={cyanClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "teal" && (<button className={tealClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "lime" && (<button className={limeClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "slate" && (<button className={slateClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "zinc" && (<button className={zincClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "amber" && (<button className={amberClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
            {props.renk === "stone" && (<button className={stoneClass} onClick={props.onClick} disabled={props.loading}>{props.loading ? <img src={Spinner} className="h-[25px]" alt={props.title}/> : props.title}</button>)}
        </>
        
  )
}

export default LoadingButton