import React from 'react'

const Kriter12 = (props) => {
  return (
    <div className={`w-full rounded-2xl mt-2 px-4 py-2 flex flex-row justify-start items-start`}>
        {props.children}
    </div>
  )
}

export default Kriter12