import React, { Fragment, useEffect, useState } from 'react';
import AdminLayoutPageHeader from '../../layouts/AdminLayoutPageHeader';
import WrapRow from '../../components/designs/WrapRow';
import Card12 from '../../components/designs/Card12';
import LoadingButton from '../../components/LoadingButton';
import Button from '../../components/Button';
import {SehirlerService, UlkelerService} from '../../services/index';
import PaginationDiv from '../../components/PaginationDiv';
import Modal from '../../components/Modal';
import {useKullaniciAuth} from '../../contexts/AdminAuthProvider';
import { ToastContainer, toast } from 'react-toastify';
import Kriter03 from '../../components/designs/Kriter03';
import Kriter12 from '../../components/designs/Kriter12';
import KriterWrap from '../../components/designs/KriterWrap';

const Sehirler = () => {
  //

  const {kullanici} = useKullaniciAuth();
  const [isLoading,setIsLoading] = useState(false);
  const [kriter,setKriter] = useState({Ara:'',Durum:2,UlkeId:0});
  const [sehirler,setSehirler] = useState([]);
  const [ulkeler,setUlkeler] = useState([]);
  const [sehirEkleModal,setSehirEkleModal] = useState(false);
  const [sehirEkle,setSehirEkle] = useState({SehirAdi:'',UlkeId:0,KayitKullaniciId:kullanici.KullaniciId});
  const [sehirGuncelle,setSehirGuncelle] = useState({SehirId:0,SehirAdi:'',UlkeId:0});
  const [sehirGuncelleModal,setSehirGuncelleModal] = useState(false);
  

  const kriterChangeEvent = (e) => {
    setKriter({...kriter,[e.target.name]:e.target.value});
  }

  const ulkeleriDoldur = () => {
    setIsLoading(true);
    UlkelerService.AramayaGoreGetir("TÜMÜ","","TÜMÜ")
    .then(response => {
      setIsLoading(false);
      setUlkeler(response);
    });
  }
  const sehirGuncelleAc = (sehirid) => {
    setIsLoading(true);
    SehirlerService.SehirIdyeGoreGetir(sehirid)
    .then(response => {
      setIsLoading(false);
      setSehirGuncelle(response);
      setSehirGuncelleModal(true);
    });
  }

  const sehirEkleChangeHandle = (e) => {
    setSehirEkle({...sehirEkle,[e.target.name]:e.target.value});
  }

  const sehirGuncelleChangeHandle = (e) => {
    setSehirGuncelle({...sehirGuncelle,[e.target.name]:e.target.value});
  }

  const sehirEkleClick = () => {
    setIsLoading(true);
    SehirlerService.SehirEkle(sehirEkle)
    .then(response => {
      setIsLoading(false);
      if(response.Sonuc){
        toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        setSehirEkle({SehirAdi:'',UlkeId:0,KayitKullaniciId:kullanici.KullaniciId});
        setSehirEkleModal(false);
        araClick();
      }else{
        toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
      }
    });
  }

  const araClick = () => {
    setIsLoading(true);
    SehirlerService.SehirlerListesiniGetir(kriter.UlkeId,kriter.Durum,kriter.Ara)
    .then(response => {
      setIsLoading(false);
      setSehirler(response);
    });
  }

  const sehirBilgiGuncelleClick = () => {
    setIsLoading(true);
    SehirlerService.SehirBilgiGuncelle(sehirGuncelle)
    .then(response => {
      setIsLoading(false);
      if(response.Sonuc){
        toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        setSehirGuncelle({SehirId:0,SehirAdi:'',UlkeId:0});
        setSehirGuncelleModal(false);
        araClick();
      }else{
        toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
      }
    });
  }

  const sehirDurumDegistir = (aktif) => {
    let veri = {SehirId:sehirGuncelle.SehirId,Aktif:aktif,AktifKullaniciId:kullanici.KullaniciId};
    setIsLoading(true);
    SehirlerService.SehirDurumGuncelle(veri)
    .then(response => {
      setIsLoading(false);
      if(response.Sonuc){
        toast.success(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
        setSehirGuncelle({SehirId:0,SehirAdi:'',UlkeId:0});
        setSehirGuncelleModal(false);
        araClick();
      }else{
        toast.error(response.SonucAciklama, {position: "top-right",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,theme: "light",});
      }
    });
  }

  useEffect(() => {
    ulkeleriDoldur();
    araClick();
  },[]);

  return (
    <>
        <ToastContainer/>
        <AdminLayoutPageHeader title="Şehirler"/>
        <WrapRow isLoading={isLoading}>
          <Card12>
              <Kriter12>
                <Button renk="green" onClick={() => setSehirEkleModal(true)}>Şehir Ekle</Button>
              </Kriter12>
              <KriterWrap>
                <Kriter03 title="Ülke :">
                  <select name='UlkeId' disabled={isLoading} value={kriter.UlkeId} className={`border border-gray-700 p-2 rounded-md mb-2`} onChange={kriterChangeEvent}>
                        <option value="0">Tüm Ülkeler</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                </Kriter03>
                <Kriter03 title="Durum :">
                  <select name='Durum' disabled={isLoading} value={kriter.Durum} className={`border border-gray-700 p-2 rounded-md mb-2`} onChange={kriterChangeEvent}>
                        <option value="0">Pasif Şehirler</option>
                        <option value="1">Aktif Şehirler</option>
                        <option value="2">Tüm Durumlar</option>
                    </select>
                </Kriter03>
                <Kriter03 title="Ara :">
                  <input disabled={isLoading} name='Ara'
                         className={`border border-gray-700 p-2 rounded-md mb-2 `} 
                         onChange={kriterChangeEvent} value={kriter.Ara}
                         type="text" placeholder='Arama yapın' autoComplete='off' />
                </Kriter03>
                <Kriter03 title="&nbsp;">
                  <LoadingButton size={100} renk="indigo" onClick={araClick} title="Ara" loading={isLoading}/>
                </Kriter03>
              </KriterWrap>
              <div className='flex flex-wrap justify-start'>
                    <PaginationDiv isLoading={isLoading} bosVeriUyarisi="Arama kriterlerine uygun veri bulunamadı !.." sayfalama={true} sayfaSayisi={20}
                        body={sehirler.map(veri => (
                            <div key={veri.SehirId} onClick={() => {sehirGuncelleAc(veri.SehirId)}}
                                className='flex flex-row justify-start items-center  w-1/2 p-1 cursor-pointer'>
                                <div className={`p-4 flex flex-row justify-start items-center ${veri.Aktif ? "bg-white hover:bg-gray-200" : "bg-white hover:bg-gray-200"}  rounded-md w-full border border-stone-800 `}>
                                    <img className='w-[70px] h-10' src={process.env.REACT_APP_API_URL + veri.UlkeBayrakYolURL}/>
                                    <div className='flex flex-col justify-center'>
                                        <span className={`ml-5 font-bold ${veri.Aktif ? "text-black":"text-gray-300"}`}>{veri.SehirAdi}</span>
                                        <span className={`ml-5 font-light text-sm ${veri.Aktif ? "text-black":"text-gray-300"}`}>{veri.UlkeAdi}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    />
                </div>
          </Card12>
        </WrapRow>
        
        {sehirEkleModal && (
          <Modal
          Baslik="Yeni Şehir Ekle"
          KapatTusu={true}
          kapatFonksiyon={() => setSehirEkleModal(false)}
          bodyIsHtml={false}
          body={
              <div className='w-[600px] flex flex-col justify-start'>
                  <div className='flex flex-row justify-between items-center'>
                      <span className='text-black w-1/4'>Şehir Adı :</span>
                      <input disabled={isLoading} name="SehirAdi" value={sehirEkle.SehirAdi} className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={sehirEkleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='text-black w-1/4'>Ülke :</span>
                      <select name='UlkeId' disabled={isLoading} value={sehirEkle.UlkeId} className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} onChange={sehirEkleChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                  </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={sehirEkleClick} title="Ekle" loading={isLoading}/>
                  <Button renk="white" onClick={() => setSehirEkleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}



        {sehirGuncelleModal && (
          <Modal
          Baslik="Şehir Güncelle"
          KapatTusu={true}
          kapatFonksiyon={() => setSehirGuncelleModal(false)}
          bodyIsHtml={false}
          body={
              <div className='w-[600px] flex flex-col justify-start'>
                  <div className='flex flex-row justify-between items-center'>
                      <span className='text-black w-1/4'>Şehir Adı :</span>
                      <input disabled={isLoading} name="SehirAdi" value={sehirGuncelle.SehirAdi} className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} type="text" autoComplete='off' 
                        onChange={sehirGuncelleChangeHandle}/>
                  </div>
                  <div className='flex flex-row justify-between items-center mt-2'>
                      <span className='text-black w-1/4'>Ülke :</span>
                      <select name='UlkeId' disabled={isLoading} value={sehirGuncelle.UlkeId} className={`border border-gray-700 p-2  rounded-md mr-3 w-3/4`} onChange={sehirGuncelleChangeHandle}>
                        <option value="0">Seçiniz</option>
                        {ulkeler.map((ulke) => (
                          <option key={ulke.UlkeId} value={ulke.UlkeId}>{ulke.UlkeAdi}</option>
                        ))}
                    </select>
                  </div>
              </div>
          }
          footer={
              <Fragment>
                  <LoadingButton renk="green" onClick={sehirBilgiGuncelleClick} title="Güncelle" loading={isLoading}/>
                  {sehirGuncelle.Aktif ? (
                    <LoadingButton renk="red" onClick={() => {sehirDurumDegistir(false)}} title="Pasifleştir" loading={isLoading}/>
                  ) : (
                    <LoadingButton renk="teal" onClick={() => {sehirDurumDegistir(true)}} title="Aktifleştir" loading={isLoading}/>
                  )}
                  
                  
                  <Button renk="white" onClick={() => setSehirGuncelleModal(false)}>Çıkış</Button>
              </Fragment>
          }
          />
        )}

    </>
  )
}

export default Sehirler
