import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/tailwindcss.css';
import './assets/css/tooltip.css';
import { ContextProvider } from './contexts/ContextProvider';
import { AdminAuthProvider } from './contexts/AdminAuthProvider';

import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';
import global_tr from './translations/tr/global.json';
import global_de from './translations/de/global.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation:{escapeValue:false},
  lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
  resources:{
    en:{ global:global_en},
    es:{ global:global_es},
    tr:{ global:global_tr},
    de:{ global:global_de},
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextProvider>
      <AdminAuthProvider>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>          
      </AdminAuthProvider>
  </ContextProvider>
);